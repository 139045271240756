import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AgmCoreModule } from "@agm/core";
import { MapsModule } from "@syncfusion/ej2-angular-maps";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import * as $ from "jquery";

import { NgxPaginationModule } from "ngx-pagination";
import { Ng2OrderModule } from "ng2-order-pipe";
import { OrderModule } from "ngx-order-pipe";
import { ToastrModule } from "ngx-toastr";
import {
  SwiperModule,
  SwiperConfigInterface,
  SWIPER_CONFIG,
} from "ngx-swiper-wrapper";
import { Select2Module } from "ng2-select2";
import { FlashMessagesModule } from "angular2-flash-messages";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FilterPipe } from "./common/filter.pipe";

import { LoginComponent } from "./login/login.component";
import { LayoutComponent } from "./layout/layout.component";
import { HeaderComponent } from "./layout/header/header.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { AuthGuard } from "./guards/auth-guard.service";
import { Globals } from "./globals";
import { NotfoundComponent } from "./notfound/notfound.component";

import { CompanyloginComponent } from "./companylogin/companylogin.component";
// import { CompanyDashboardComponent } from './company-dashboard/company-dashboard.component';
import { CompanyHeaderComponent } from "./layout/company-header/company-header.component";
import { BatchlistComponent } from "./company/batch/batchlist/batchlist.component";
import { SkuComponent } from "./company/sku/sku.component";
import { AddSkuComponent } from "./company/sku/add-sku/add-sku.component";
import { EditSkuComponent } from "./company/sku/edit-sku/edit-sku.component";
import { UserComponent } from "./company/business/user/user.component";
import { AddUserComponent } from "./company/business/user/add-user/add-user.component";
import { EditUserComponent } from "./company/business/user/edit-user/edit-user.component";
import { AddqrcodeComponent } from "./company/qrcode/addqrcode/addqrcode.component";
import { ListqrcodeComponent } from "./company/qrcode/listqrcode/listqrcode.component";
import { AddshipperqrcodeComponent } from "./company/shipperqrcode/addshipperqrcode/addshipperqrcode.component";
import { ListshipperqrcodeComponent } from "./company/shipperqrcode/listshipperqrcode/listshipperqrcode.component";
import { SkudetailsComponent } from "./company/sku/skudetails/skudetails.component";
import { RoledetailsComponent } from "./company/qrcode/roledetails/roledetails.component";
import { DetailshipperqrcodeComponent } from "./company/shipperqrcode/detailshipperqrcode/detailshipperqrcode.component";
import { ImportSkuComponent } from "./company/sku/import-sku/import-sku.component";
import { ImportshipperqrcodeComponent } from "./company/shipperqrcode/importshipperqrcode/importshipperqrcode.component";
import { ChartsModule } from "ng2-charts";
import { StockComponent } from "./company/reports/stock-reports/stock/stock.component";
import { StockDetailsComponent } from "./company/reports/stock-reports/stock-details/stock-details.component";
import { StockCfaListComponent } from "./company/reports/stock-reports/stock-cfa-list/stock-cfa-list.component";
import { AddManufacturerComponent } from "./company/manufacturer/add-manufacturer/add-manufacturer.component";
import { ViewManufacturerComponent } from "./company/manufacturer/view-manufacturer/view-manufacturer.component";
import { UpdateManufacturerComponent } from "./company/manufacturer/update-manufacturer/update-manufacturer.component";
import { CustomerCareComponent } from './company/customer-care/customer-care.component';
import { EditCustomerCareComponent } from './company/edit-customer-care/edit-customer-care.component';
import { AddCustomerCareComponent } from './components/add-customer-care/add-customer-care.component';
import { FstLvlInnerCodesComponent } from './components/fst-lvl-inner-codes/fst-lvl-inner-codes.component';
import { SndLvlInnerCodesComponent } from './components/snd-lvl-inner-codes/snd-lvl-inner-codes.component';
import { CodeGeneratedComponent } from './code_generated/code_generated.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: "horizontal",
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true,
};

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    LoginComponent,
    FilterPipe,
    CompanyloginComponent,
    CodeGeneratedComponent,
    // CompanyDashboardComponent,
    CompanyHeaderComponent,
    BatchlistComponent,    
    SkuComponent,
    AddSkuComponent,
    EditSkuComponent,
    UserComponent,
    AddUserComponent,
    EditUserComponent,
    NotfoundComponent,
    AddqrcodeComponent,
    ListqrcodeComponent,
    AddshipperqrcodeComponent,
    ListshipperqrcodeComponent,    
    SkudetailsComponent,
    RoledetailsComponent,
    DetailshipperqrcodeComponent,
    ImportSkuComponent,
    ImportshipperqrcodeComponent,
    StockComponent,
    StockDetailsComponent,
    StockCfaListComponent,
    AddManufacturerComponent,
    ViewManufacturerComponent,
    UpdateManufacturerComponent,
    CustomerCareComponent,
    EditCustomerCareComponent,
    AddCustomerCareComponent,
    FstLvlInnerCodesComponent,
    SndLvlInnerCodesComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ChartsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    Ng2OrderModule,
    OrderModule,
    RouterModule,
    SwiperModule,
    MapsModule,
    FlashMessagesModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-bottom-right",
    }),
    BrowserAnimationsModule,
    NgbModule.forRoot(),
    Select2Module,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCsoNJqaxw2Lbk3xrWtucUbKPjchimqwaw",
      // libraries: ["maps"]
    }),
  ],
  providers: [
    AuthGuard,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    Globals,
    { provide: HTTP_INTERCEPTORS, useClass: AuthGuard, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
