import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OrderPipe } from "ngx-order-pipe";
import { Globals } from "src/app/globals";
import { ApiService } from "src/app/service/api-service/api-service.service";
import * as moment from "moment";
import { AngularCsv } from "angular7-csv/dist/Angular-csv";
import * as XLSX from 'xlsx';

@Component({
  selector: "app-fst-lvl-inner-codes",
  templateUrl: "./fst-lvl-inner-codes.component.html",
  styleUrls: ["./fst-lvl-inner-codes.component.css"],
})
export class FstLvlInnerCodesComponent implements OnInit {
  data;
  searchText = "";
  sortedCollection: any[];
  order: string = "createdAt";
  reverse: boolean = true;
  result: any = [{}];
  arr: any = [];
  errors: any = "";
  deletedMsg: string = null;
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  deleteId: any;
  pageLimits = this.globals.pageLimits;
  loader: boolean = false;

  csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    showTitle: false,
    title: "Qrcode List :",
    useBom: true,
    noDownload: false,
    headers: ["Data", "SKU", "Batch No.", "Mfg. Date", "Exp. Date", "Quantity", "Subcode"], //headers: ["QrCode"]
  };

  selectedQRCodeID;
  radioBtn;
  selectedSeparator;

  constructor(
    private router: Router,
    private orderPipe: OrderPipe,
    private globals: Globals,
    private apiService: ApiService
  ) {
    this.sortedCollection = this.orderPipe.transform(this.result, this.order);
  }

  ngOnInit() {
    this.getFirstLvlInnerList();
  }

  getFirstLvlInnerList() {
    this.loader = true;
    this.apiService.get("company/fst-lvl-inner/list", true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          this.result = response.body.data;
        }
        this.loader = false;
      },
      (error) => {
        this.loader = false;
        console.error(error);
      }
    );
  }

  getQRCodes() {
    this.loader = true;

    let formatter = this.radioBtn;

    if (this.selectedSeparator == '.') {
      formatter = formatter.replace(/-/g, ".")
    }

    this.apiService.get(`company/fst-lvl-inner/get?id=${this.selectedQRCodeID}`, true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          let qrCode = [];
          qrCode = response.body.data.map((item) => {
            return {
              "QR Codes": `${(item.product.gtin) ? item.product.gtin : 'UID ' + item.unique_qrcode} B.NO ${item.product_batch.batch_no
                } MFG DT ${moment(item.product_batch.manufacturing_date)
                  .format(formatter)
                  .toUpperCase()} EXP DT ${moment(item.product_batch.expiry_date)
                    .format(formatter)
                    .toUpperCase()} www.ttags.in/${item.unique_qrcode}`,
              "Batch No.": item.product_batch.batch_no,
              "Mfg. Date": moment(
                new Date(item.product_batch.manufacturing_date)
              ).format(formatter),
              "Exp. Date": moment(
                new Date(item.product_batch.expiry_date)
              ).format(formatter),
              "MRP": item.product.product_mrp,
              "Sub Codes": item.unique_qrcode,
              "SKU": item.product.sku
            };
          });

          let fileName =
            response.body.data[0].product.sku +
            "_" +
            response.body.data[0].product_batch.batch_no +
            "_first_inner.xlsx";

          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(qrCode);
          ws['!cols'] = [{ width: 80 }, { width: 10 }, { width: 20 }, { width: 20 }, { width: 10 }, { width: 10 }, { width: 10 }]
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          XLSX.writeFile(wb, fileName);
          // new AngularCsv(qrCode, fileName, this.csvOptions);
          this.loader = false;
        }
      },
      (error) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  exportQrcode(id) {
    this.loader = true;
    this.apiService
      .get(`company/fst-lvl-inner/pdf-download?random_id=${id}`, true, true)
      .subscribe(
        (response: any) => {
          if (response.status === 200) {
            var contentDispositionHeader = response.headers.get(
              "Content-Disposition"
            );
            console.log("response->", response);
            console.log("contentDispositionHeader->", contentDispositionHeader);
            let fileName = contentDispositionHeader.split("=")[1];
            this.downloadPDF(response.body, "application/pdf", fileName);
          }
        },
        (err) => {
          console.error(err);
          this.loader = false;
        }
      );
  }

  downloadPDF(data: any, type: string, filename) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    var fileLink = document.createElement("a");
    fileLink.href = url;

    fileLink.download = filename;

    // triggers the click event
    fileLink.click();
    // saveAs
    // let pwa = window.open(url);

    window.URL.revokeObjectURL(url);
    this.loader = false;
  }

  setOrder(value: string) {
    console.log("o: ", this.order, " v: ", value);
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
}
