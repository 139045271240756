import { Component, OnInit } from "@angular/core";
import { NgForm, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { CompanyService } from "../../../company/service/company.service";
import { FlashMessagesService } from "angular2-flash-messages";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as $ from "jquery";
import { Globals } from "./../../../globals";
import { ApiService } from "src/app/service/api-service/api-service.service";

@Component({
  selector: "app-edit-sku",
  templateUrl: "./edit-sku.component.html",
  styleUrls: ["./edit-sku.component.css"],
})
export class EditSkuComponent implements OnInit {
  resultList: any;
  resultData: any;
  variantdataList: any;
  resultVariant: any;
  msg: string = null;
  msg1: string = null;
  msg2: string = null;
  msg3: string = null;
  errors: any = "";
  result: any = {};
  Editid: any;
  currentUserId: any = localStorage.getItem("id");
  variantList: any;
  categoryId: any;
  brandId: any;
  variantId: any;
  unitData: any;
  packageList: any;
  packageId: any;
  public imagePath;
  imgURL: any = "/assets/images/placeholder.png";
  upload_img: string = "Upload Image";
  upload_pdf: string = "Upload PDF";
  upload_pdf1: string = "Upload PDF";
  message: any;
  companyId: number;
  main_image: any;
  filedata: any;
  sizeList: any;
  sizeId: any;
  submit: boolean = false;
  loader: boolean = false;

  fstInnerFlag: Boolean = false;
  sndInnerFlag: Boolean = false;

  cautionLogoId;
  productLogoFile;
  productLabelFile: any = null;
  productLeafletFile: any = null;

  registerForm: FormGroup;
  submitted = false;
  customerCareList: any = [];

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  constructor(
    private globals: Globals,
    private _flashMessagesService: FlashMessagesService,
    public companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      skuId: [""],
      name: ["", [Validators.required, Validators.maxLength(100)]],
      antidote: ["", Validators.required],
      skuSize: ["", Validators.required],
      shipperSize: ["", [Validators.required, Validators.min(0)]],
      productLogo: [""],
      gtin: [""/*, Validators.required*/],
      regNo: ["", [Validators.required, Validators.maxLength(100)]],
      regNo1: ["", [Validators.required, Validators.maxLength(100)]],
      sapDesc: [""],
      marketedBy: ["", Validators.required],
      fstInnerSize: [""],
      sndInnerSize: [""],
      productLabel: [""],
      productLeaflet: [""],
      productMRP: ["", Validators.pattern("^-?\\d*(\\.\\d+)?$")]
    });

    this.getCustomerCareList();

    this.route.params.subscribe((params) => {
      console.log(params);
      this.Editid = params["id"];
      this.editskuData();
      this.unitData = this.globals.unitData;
    });
  }

  getCustomerCareList() {
    this.apiService.get("company/customer-care/list", true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          this.customerCareList = response.body.data;
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  onProductLogoChange(event, value) {
    if(value == 1) {
        if(event.target.files[0]){
          console.log(event.target.files[0].type.includes("image"));
          if(event.target.files[0].type.includes("image")){
            if(event.target.files[0].size <= 500000){
              this.productLogoFile = event.target.files[0];
              this.upload_img = event.target.files[0].name;
              this.msg1 = null;
            }else{
              this.upload_img = "Upload Image"
              this.productLogoFile = null;
              this.msg1 = "Product Image File must be less than 500KB";    
            }
          }else{
            console.log("1");
            this.productLabelFile = null;
            this.upload_pdf = "Upload PDF"
            this.msg1 = "Product Image must be image file";
          }  
        }else {
          this.productLogoFile = null;
          this.upload_img = "Upload Image"
          this.msg1 = null;
        }
      }else if(value == 2){
        if(event.target.files[0]){
          if(event.target.files[0].type == "application/pdf"){
            if(event.target.files[0].size <= 500000){
              this.productLabelFile = event.target.files[0];
              this.upload_pdf = event.target.files[0].name;
              this.msg2 = null;
            }else {
              this.productLabelFile = null;
              this.upload_pdf = "Upload PDF"
              this.msg2 = "Product Label File must be less than 500KB";
            }
          }else{
            console.log("1");
            this.productLabelFile = null;
            this.upload_pdf = "Upload PDF"
            this.msg2 = "Product Label must be PDF file";
          }
        }else {
          this.productLabelFile = null;
          this.msg2 = null;
          this.upload_pdf = "Upload PDF"
        } 
      }else if(value == 3){
        if(event.target.files[0]){
          if(event.target.files[0].type == "application/pdf"){
            if(event.target.files[0].size <= 500000){
              this.productLeafletFile = event.target.files[0];
              this.upload_pdf1 = event.target.files[0].name;
              this.msg3 = null;
            }else {
              this.productLeafletFile = null;
              this.upload_pdf1 = "Upload PDF"
              this.msg3 = "Product Leaflet File must be less than 500KB";
            }
          }else{
            this.productLabelFile = null;
            this.upload_pdf = "Upload PDF"
            this.msg3 = "Product Leaflet must be PDF file";
          }  
        }else {
          this.productLeafletFile = null;
          this.msg3 = null;
          this.upload_pdf1 = "Upload PDF"
        } 
      }
  }

  editskuData() {
    this.loader = true;
    this.companyService.product_edit(this.Editid).subscribe(
      (res: any) => {
        this.loader = false;
        this.result = res.data;

        console.log("customer care: ", this.customerCareList);
        console.log(this.result);

        this.registerForm.controls["fstInnerSize"].updateValueAndValidity();

        if (this.result.fst_inner_size && this.result.fst_inner_size > 0) {
          console.log(this.result.fst_inner_size);
          this.fstInnerFlag = true;
          this.registerForm.controls["fstInnerSize"].setValidators([
            Validators.required,
            Validators.min(1),
          ]);
        } else {
          this.fstInnerFlag = false;
          this.registerForm.controls["fstInnerSize"].clearValidators();
        }

        this.registerForm.controls["fstInnerSize"].updateValueAndValidity();

        if (this.result.snd_inner_size && this.result.snd_inner_size > 0) {
          console.log(this.result.snd_inner_size);
          this.sndInnerFlag = true;
          this.registerForm.controls["sndInnerSize"].setValidators([
            Validators.required,
            Validators.min(1),
          ]);
        } else {
          this.sndInnerFlag = false;
          this.registerForm.controls["sndInnerSize"].clearValidators();
        }

        this.registerForm.controls["sndInnerSize"].updateValueAndValidity();

        this.registerForm.patchValue({
          skuId: this.result.sku,
          name: this.result.name,
          sapDesc: this.result.sap_desc,
          antidote: this.result.antidote,
          skuSize: this.result.sku_size,
          shipperSize: this.result.shipper_size,
          regNo: this.result.reg_no,
          regNo1: this.result.reg_no1,
          gtin: this.result.gtin,
          marketedBy: this.result["customer_care.id"],
          productMRP: this.result.product_mrp,
          fstInnerSize:
            this.result.fst_inner_size && this.result.fst_inner_size > 0
              ? this.result.fst_inner_size
              : null,
          sndInnerSize:
            this.result.snd_inner_size && this.result.snd_inner_size > 0
              ? this.result.snd_inner_size
              : null,
        });

        this.cautionLogoId = this.result.caution_logo;
      },
      (err) => {
        this.loader = false;
      }
    );
  }

  onSelectCautionLogo(id) {
    this.cautionLogoId = id;
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    console.log(this.registerForm.value);
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.loader = true;

    let formdata = new FormData();

    formdata.append("name", this.registerForm.value.name);

    if (this.productLogoFile) {
      formdata.append(
        "main_image",
        this.productLogoFile,
        this.productLogoFile.name
      );
    }

    if (this.productLabelFile) {
      formdata.append(
        "productLabel",
        this.productLabelFile,
        this.productLabelFile.name
      );
    }
    if (this.productLeafletFile) {
      formdata.append(
        "productLeaflet",
        this.productLeafletFile,
        this.productLeafletFile.name
      );
    }
    // formdata.append("shipper_size", this.registerForm.value.shipperSize);
    formdata.append("sku_size", this.registerForm.value.skuSize);
    formdata.append("antidote", this.registerForm.value.antidote);
    formdata.append("caution_logo", this.cautionLogoId);
    formdata.append("product_mrp", this.registerForm.value.product_mrp);
    formdata.append("sap_desc", this.registerForm.value.sapDesc);
    formdata.append("regNo", this.registerForm.value.regNo);
    formdata.append("regNo1", this.registerForm.value.regNo1);
    // formdata.append("fstInnerSize", this.registerForm.value.fstInnerSize);
    // formdata.append("sndInnerSize", this.registerForm.value.sndInnerSize);
    formdata.append("marketedById", this.registerForm.value.marketedBy);
    formdata.append("gtin", this.registerForm.value.gtin);
    formdata.append("productMRP", this.registerForm.value.productMRP)

    this.companyService.product_update(this.Editid, formdata).subscribe(
      (res: any) => {
        this.loader = false;
        this.resultData = res;
        console.log(this.resultData);
        if (this.resultData.success == 1) {
          setTimeout(() => {
            this.router.navigate(["/sku"]);
          }, 1000);
        } else {
          this.errors = res.message;
        }
      },
      (err) => {
        this.loader = false;

        this.errors =
          "Due to internal server error you can't update SKU details!";
      }
    );
  }
}
