import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CompanyService } from "../../../company/service/company.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/service/api-service/api-service.service";

@Component({
  selector: "app-add-sku",
  templateUrl: "./add-sku.component.html",
  styleUrls: ["./add-sku.component.css"],
})
export class AddSkuComponent implements OnInit {
  model: any = {};
  resultList: any;
  msg: string = null;
  msg1: string = null;
  msg2: string = null;
  msg3: string = null;
  errors: any = "";
  variantdataList: any;
  variantList: any;
  unitData: any;
  resultProduct: any;
  packageList: any;
  public imagePath;
  imgURL: any = "/assets/images/placeholder.png";
  main_image: any;
  message: any;
  filedata: any;
  brandOptions: any;
  categoryOptions: any;
  variantOptions: any;
  unitOptions: any;
  packagingOptions: any;
  result: any;
  sizeList: any;
  submit: boolean = false;
  customerCareList: any = [];
  is_reg_no: boolean = false;
  upload_img: string = "Upload Image";
  upload_pdf: string = "Upload PDF";
  upload_pdf1: string = "Upload PDF";
  fstChecked = false;
  sndChecked = false;

  registerForm: FormGroup;
  submitted = false;

  loader = false;

  cautionLogoId = "red";

  productLogoFile;
  productLabelFile: any = null;
  productLeafletFile: any = null;

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  constructor(
    public companyService: CompanyService,
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService
  ) { }

  async ngOnInit() {


    this.registerForm = this.formBuilder.group({
      skuId: ["", [Validators.required, Validators.maxLength(100)]],
      name: ["", [Validators.required, Validators.maxLength(100)]],
      antidote: ["", Validators.required],
      skuSize: ["", Validators.required],
      shipperSize: ["", [Validators.required, Validators.min(1)]],
      productLogo: ["", Validators.required],
      regNo: ["", [Validators.required, Validators.maxLength(100)]],
      regNo1: ["", [Validators.required, Validators.maxLength(100)]],
      marketedBy: ["", Validators.required],
      gtin: [""/*,Validators.required*/],
      sapDesc: [""],
      fstInnerSize: [""],
      productLabel: [""],
      productLeaflet: [""],
      sndInnerSize: [""],
      productMRP: ["", Validators.pattern("^-?\\d*(\\.\\d+)?$")]
    });

    this.getCustomerCareList();
  }

  validation_change(value) {

    if (value) {
      if (this.registerForm.value.regNo == '') {
        this.registerForm.controls['regNo1'].setValidators([Validators.required]);
        this.registerForm.controls['regNo1'].updateValueAndValidity();
      } else {
        this.registerForm.controls['regNo1'].clearValidators();
        this.registerForm.controls['regNo1'].updateValueAndValidity();
      }

    } else {
      if (this.registerForm.value.regNo1 == '') {
        this.registerForm.controls['regNo'].setValidators([Validators.required]);
        this.registerForm.controls['regNo'].updateValueAndValidity();
      } else {
        this.registerForm.controls['regNo'].clearValidators();
        this.registerForm.controls['regNo'].updateValueAndValidity();
      }

    }

  }

  getCustomerCareList() {
    this.apiService.get("company/customer-care/list", true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          this.customerCareList = response.body.data;
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  onChangeFirstInner(event) {
    this.fstChecked = event.checked;
    if (event.checked) {
      this.registerForm.controls["fstInnerSize"].setValidators([
        Validators.required,
        Validators.min(1),
      ]);
    } else {
      this.registerForm.controls["fstInnerSize"].clearValidators();
    }
    this.registerForm.controls["fstInnerSize"].updateValueAndValidity();
  }

  onChangeSecondInner(event) {
    this.sndChecked = event.checked;
    if (event.checked) {
      this.registerForm.controls["sndInnerSize"].setValidators([
        Validators.required,
        Validators.min(1),
      ]);
    } else {
      this.registerForm.controls["sndInnerSize"].clearValidators();
    }
    this.registerForm.controls["sndInnerSize"].updateValueAndValidity();
  }

  onUploadSuccess(event) {
    console.log(event);
  }

  get f() {
    return this.registerForm.controls;
  }

  onSelectCautionLogo(id) {
    this.cautionLogoId = id;
  }

  onRegisterFormReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  onProductLogoChange(event, value) {
    console.log(event.target.files[0]);
    if (value == 1) {
      if (event.target.files[0]) {
        console.log(event.target.files[0].type.includes("image"));
        if (event.target.files[0].type.includes("image")) {
          if (event.target.files[0].size <= 500000) {
            this.productLogoFile = event.target.files[0];
            this.upload_img = event.target.files[0].name;
            this.msg1 = null;
          } else {
            this.upload_img = "Upload Image"
            this.productLogoFile = null;
            this.msg1 = "Product Image File must be less than 500KB";
          }
        } else {
          console.log("1");
          this.productLabelFile = null;
          this.upload_pdf = "Upload PDF"
          this.msg1 = "Product Image must be image file";
        }
      } else {
        this.productLogoFile = null;
        this.upload_img = "Upload Image"
        this.msg1 = null;
      }
    } else if (value == 2) {
      if (event.target.files[0]) {
        if (event.target.files[0].type == "application/pdf") {
          if (event.target.files[0].size <= 500000) {
            this.productLabelFile = event.target.files[0];
            this.upload_pdf = event.target.files[0].name;
            this.msg2 = null;
          } else {
            this.productLabelFile = null;
            this.upload_pdf = "Upload PDF"
            this.msg2 = "Product Label File must be less than 500KB";
          }
        } else {
          console.log("1");
          this.productLabelFile = null;
          this.upload_pdf = "Upload PDF"
          this.msg2 = "Product Label must be PDF file";
        }
      } else {
        this.productLabelFile = null;
        this.msg2 = null;
        this.upload_pdf = "Upload PDF"
      }
    } else if (value == 3) {
      if (event.target.files[0]) {
        if (event.target.files[0].type == "application/pdf") {
          if (event.target.files[0].size <= 500000) {
            this.productLeafletFile = event.target.files[0];
            this.upload_pdf1 = event.target.files[0].name;
            this.msg3 = null;
          } else {
            this.productLeafletFile = null;
            this.upload_pdf1 = "Upload PDF"
            this.msg3 = "Product Leaflet File must be less than 500KB";
          }
        } else {
          this.productLabelFile = null;
          this.upload_pdf = "Upload PDF"
          this.msg3 = "Product Leaflet must be PDF file";
        }
      } else {
        this.productLeafletFile = null;
        this.msg3 = null;
        this.upload_pdf1 = "Upload PDF"
      }
    }
  }



  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.loader = true;

    let formdata = new FormData();
    console.log(this.productLogoFile);
    formdata.append(
      "main_image",
      this.productLogoFile,
      this.productLogoFile.name
    );
    if (this.productLabelFile) {
      formdata.append("productLabel", this.productLabelFile, this.productLabelFile.name);
    }
    if (this.productLeafletFile) {
      formdata.append("productLeaflet", this.productLeafletFile, this.productLeafletFile.name);
    }
    formdata.append("skuSize", this.registerForm.value.skuSize);
    formdata.append("shipperSize", this.registerForm.value.shipperSize);
    formdata.append("antidote", this.registerForm.value.antidote);
    formdata.append("sku_id", this.registerForm.value.skuId);
    formdata.append("productName", this.registerForm.value.name);
    formdata.append("marketedById", this.registerForm.value.marketedBy);
    formdata.append("cEmail", this.registerForm.value.cEmail);
    formdata.append("regNo", this.registerForm.value.regNo);
    formdata.append("regNo1", this.registerForm.value.regNo1);
    formdata.append("sapDesc", this.registerForm.value.sapDesc);
    formdata.append("cautionLogo", this.cautionLogoId);
    formdata.append("fstInnerSize", this.registerForm.value.fstInnerSize);
    formdata.append("sndInnerSize", this.registerForm.value.sndInnerSize);
    formdata.append("gtin", this.registerForm.value.gtin);
    formdata.append("productMRP", this.registerForm.value.productMRP)

    this.companyService.product_add(formdata).subscribe(
      (res: any) => {
        this.loader = false;
        console.log(res);

        this.resultProduct = res;
        if (this.resultProduct.success == 1) {
          this.router.navigate(["/sku"]);
        } else {
          this.msg = res.message;
        }
      },
      (err) => {
        this.loader = false;

        this.msg = "Due to issue on server, you can't add product details!";
      }
    );
  }
}
