import { Component, OnInit } from "@angular/core";
import { NgForm, Validators, FormGroup, FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { CompanyService } from "../../../company/service/company.service";
import { FlashMessagesService } from "angular2-flash-messages";
// import * as $ from "jquery";
import { Globals } from "./../../../globals";

declare var jQuery : any;

@Component({
  selector: "app-addqrcode",
  templateUrl: "./addqrcode.component.html",
  styleUrls: ["./addqrcode.component.css"],
})
export class AddqrcodeComponent implements OnInit {
  public errors: any = "";
  public model: any = {};
  public result: any;
  public skuOptions: any;
  public skus: any;
  public skuData: any;
  public batchData: any;
  public errorMessage: string = "";

  registerForm: FormGroup;
  submitted = false;

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  public loader: boolean = false;

  constructor(
    private _flashMessagesService: FlashMessagesService,
    public companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private globals: Globals,
    private formBuilder: FormBuilder
  ) {}

  /**
   * [ngOnInit description]
   * @author Suresh Suthar
   * @Created Date 2019-04-04
   */
  async ngOnInit() {
    this.registerForm = this.formBuilder.group({
      total_qr_code: [
        "",
        [Validators.required, Validators.max(10000), Validators.min(1)],
      ],
      sku_id: ["", Validators.required],
      batch_id: ["", Validators.required],
      percentage_error: ["", [Validators.max(100), Validators.min(1)]],
    });

    this.skuOptions = { placeholder: { id: "", text: "Select SKU" } };
    await this.getSkuData();

    jQuery('.select2').select2();
    jQuery('.select2').on('select2:select', (event) => {
      console.log(event.target.value)
      this.registerForm.patchValue({sku_id: event.target.value});
      // if(this.registerForm.value.sku_id != ''){
        this.getbatchData();
      // }
      // code you want to execute when you hit select2 combo  
  });
  }

  get f() {
    return this.registerForm.controls;
  }

  /**
   * [getSkuData description]
   * @author Suresh Suthar
   * @Created Date 2019-04-04
   */
  async getSkuData() {
    await this.companyService.product_list().subscribe((res: any) => {
      this.skuData = res.data;
      var rObj = [];
      for (let i = 0; i < res.data.length; i++) {
        let sku = res.data[i].sku + " (" + res.data[i].name + ")";
        rObj.push({ id: res.data[i].id, text: sku });
      }

      this.skus = rObj;
      console.log("skus->", this.skus);
    });
  }

  async getbatchData() {
   
    await this.companyService.getSKUBatchData(this.registerForm.value.sku_id).subscribe((res: any) => {
      //this.batchData = res.data;
      var rObj = [];
      for (let i = 0; i < res.data.length; i++) {
        let batch = res.data[i].batch_no;
        rObj.push({ id: res.data[i].id, text: batch });
      }

      this.batchData = rObj;
      console.log("batchData->", this.batchData);
    });
  }

  /**
   * [calculateRoleCount description]
   * @author Suresh Suthar
   * @Created Date 2019-04-04
   */
  calculateRoleCount(event) {
    if (
      this.registerForm.value.qr_code_per_role > 0 &&
      this.registerForm.value.total_qr_code > 0
    ) {
      let remainingQrcode =
        this.registerForm.value.total_qr_code %
        this.registerForm.value.qr_code_per_role;
      this.errorMessage =
        remainingQrcode > 0
          ? "Total Qr code should be multiple of total role"
          : "";
    }
  }

 setSkuId(event) {
   console.log('---', event, this.registerForm.value);
    //this.errors.sku_id="";
    this.registerForm.patchValue({batch_id:''})
    if(this.registerForm.value.sku_id != ''){
      this.getbatchData();
    }
    
  }

  onSubmit(form?: NgForm) {
    
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    // let formData = new FormData();
    // formData.sku_id = this.registerForm.value.sku_id;
    // formData.qr_code_per_role = this.registerForm.value.total_qr_code;
    // formData.percentage_error = this.registerForm.value.percentage_error;
    // formData.invoice_no = this.model.invoice_no;

    // console.log(formData);
    // return false;
    if (!this.errorMessage) {
      this.loader = true;
      this.companyService
        .createSKUQrcode({
          sku_id: this.registerForm.value.sku_id,
          batch_id: this.registerForm.value.batch_id,
          qr_code_per_role: this.registerForm.value.total_qr_code,
          total_qr_code: this.registerForm.value.total_qr_code,
          percentage_error: this.registerForm.value.percentage_error
            ? Number(this.registerForm.value.percentage_error)
            : 0,
        })
        .subscribe(
          (res: any) => {
            this.loader = false;
            this.result = res.data;

            if (res.success == 1) {
              this._flashMessagesService.show(
                "Product codes generated successfully",
                {
                  cssClass: "alert-success",
                }
              );

              setTimeout(() => {
                this.router.navigate(["/qrcode"]);
              }, 3000);
            } else {
              this.errors = res.message;
              this._flashMessagesService.show(res.message, {
                cssClass: "alert-danger",
              });
            }
          },
          (err) => {
            this.loader = false;
          }
        );
    }
  }
}
