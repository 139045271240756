import { Component, OnInit } from '@angular/core';
import { ApiService } from "src/app/service/api-service/api-service.service";
import { Globals } from "./../../../globals";
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-view-manufacturer',
  templateUrl: './view-manufacturer.component.html',
  styleUrls: ['./view-manufacturer.component.css']
})
export class ViewManufacturerComponent implements OnInit {

  manufacturerList = [];
  loader = false;  

  searchText = "";
  sortedCollection: any[];
  order: string = "name";
  reverse: boolean = false;
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  pageLimits = this.globals.pageLimits;
  deleteId: any;


  constructor(private apiService: ApiService,
    private globals: Globals,
    private _flashMessagesService: FlashMessagesService) { }

  ngOnInit() {
    this.getManufacturerDetails();
  }

  setOrder(value: string) {
    console.log(value);
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  opendeleteModal(id) {
    this.deleteId = id;
    // $('#exampleModal').modal('show');
  }

  // productDelete(id) {
  //   this.loader = true;
  //   this.apiService.delete(`company/manufacturer?id=${id}`, true).subscribe(
  //     (res: any) => {
  //       this.loader = false;
        
  //         this.getManufacturerDetails();            
  //     },
  //     (err) => {
  //       this.loader = false;
  //       this._flashMessagesService.show("Due to internal server issue you can't delete it!", {
  //         cssClass: "alert-danger",
  //       });
  //     }
  //   );
  // }


  getManufacturerDetails(){
    this.loader = true;
    this.apiService.get(`company/manufacturer/all`, true).subscribe((response: any) =>{
      this.loader = false;
      console.log(response.body)
      if(response.status == 200  && response.body.success == 1) {
        this.manufacturerList = response.body.data;
      }
    }, error => {
      console.error(error);
      this.loader = false;      
    });
  }

}
