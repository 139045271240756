import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { CompanyService } from "../company/service/company.service";
declare var $: any;
import { OrderPipe } from "ngx-order-pipe";
import { FlashMessagesService } from "angular2-flash-messages";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Globals } from "./../globals";

@Component({
  selector: "app-code_generated",
  templateUrl: "./code_generated.component.html",
  styleUrls: ["./code_generated.component.css"],
})
export class CodeGeneratedComponent implements OnInit {
  data: any;
  searchText = "";
  sortedCollection: any[];
  order: string = "datetime";
  reverse: boolean = false;
  result: any = [];
  arr: any = [];
  errors: any = "";
  howtouseId: any;
  deletedMsg: string = null;
  deleteId: any;
  start: any = null;
  end: any = null;
  featureId: any = [];
  howtouse: any = [];
  featureValue: any;
  howtouseValue: any;
  resultdetails: any;
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  featureId1: any;
  unitData: any;
  result1: any = {};
  howresult: any;
  submit: boolean = false;
  is_scan_on_production_line = 'gen';
  pageLimits = this.globals.pageLimits;
  codelist: any = [];
  loader: boolean = false;
  total: number = 0;
  currentdate: any = null;

  constructor(
    private globals: Globals,
    private _flashMessagesService: FlashMessagesService,
    public companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private orderPipe: OrderPipe
  ) {
    this.sortedCollection = orderPipe.transform(this.result, this.order);


    var dtToday = new Date();
    dtToday = new Date(dtToday.setMonth(dtToday.getMonth() + 1));
    var month = dtToday.getMonth();


    if (month == 0)
      month = 1;

    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (day < 10) {
      var maxDate = '0' + day.toString();
    } else {
      var maxDate = day.toString();
    }
    if (month < 10) {
      maxDate = year + '-' + '0' + month.toString() + '-' + maxDate;
    } else {
      maxDate = year + '-' + month.toString() + '-' + maxDate;
    }
    this.end = maxDate;
    this.currentdate = maxDate;


    var dtToday = new Date();
    dtToday = new Date(dtToday.setMonth(dtToday.getMonth() - 1));
    var month = dtToday.getMonth();
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month == 0)
      month = 1;
    console.log(month);
    if (day < 10) {
      var maxDate = '0' + day.toString();
    } else {
      var maxDate = day.toString();
    }
    if (month < 10) {
      maxDate = year + '-' + '0' + month.toString() + '-' + maxDate;
    } else {
      maxDate = year + '-' + month.toString() + '-' + maxDate;
    }


    

    this.start = maxDate;

    console.log(this.start);
    console.log(this.end);
  }

  ngOnInit() {
    this.unitData = this.globals.unitData;
    this.getcode();
  }

  getcode() {
    this.loader = true;
    this.companyService.codelist("?reportFor=" + this.is_scan_on_production_line + "&startDate=" + this.start + "&endDate=" + this.end).subscribe((res: any) => {
      this.loader = false;
      if (res.success == 1) {
        console.log(res.data)
        this.codelist = res.data;
        this.total = res.total;
        /*for (let i = 0; i < this.codelist.length; i++) {
          for (let j = 0; j < this.unitData.length; j++) {
            if (this.codelist[i]["option.unit"] == this.unitData[j].id) {
              this.codelist[i].unit_value = this.unitData[j].text;
            }
          }
        }*/
      }
    });
  }
  setOrder(value: string) {
    console.log(value, this.order);
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  onchangecode() {
    this.getcode();

  }
}
