import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CompanyloginComponent } from "./companylogin/companylogin.component";
import { AuthGuard } from "./guards/auth-guard.service";
// import { CompanyDashboardComponent } from './company-dashboard/company-dashboard.component';
import { BatchlistComponent } from "./company/batch/batchlist/batchlist.component";
import { SkuComponent } from "./company/sku/sku.component";
import { AddSkuComponent } from "./company/sku/add-sku/add-sku.component";
import { EditSkuComponent } from "./company/sku/edit-sku/edit-sku.component";
import { UserComponent } from "./company/business/user/user.component";
import { AddUserComponent } from "./company/business/user/add-user/add-user.component";
import { EditUserComponent } from "./company/business/user/edit-user/edit-user.component";
import { NotfoundComponent } from "./notfound/notfound.component";
import { AddqrcodeComponent } from "./company/qrcode/addqrcode/addqrcode.component";
import { ListqrcodeComponent } from "./company/qrcode/listqrcode/listqrcode.component";
import { AddshipperqrcodeComponent } from "./company/shipperqrcode/addshipperqrcode/addshipperqrcode.component";
import { ListshipperqrcodeComponent } from "./company/shipperqrcode/listshipperqrcode/listshipperqrcode.component";
import { SkudetailsComponent } from "./company/sku/skudetails/skudetails.component";
import { RoledetailsComponent } from "./company/qrcode/roledetails/roledetails.component";
import { DetailshipperqrcodeComponent } from "./company/shipperqrcode/detailshipperqrcode/detailshipperqrcode.component";
import { ImportSkuComponent } from "./company/sku/import-sku/import-sku.component";
import { ImportshipperqrcodeComponent } from "./company/shipperqrcode/importshipperqrcode/importshipperqrcode.component";
import { StockComponent } from "./company/reports/stock-reports/stock/stock.component";
import { StockDetailsComponent } from "./company/reports/stock-reports/stock-details/stock-details.component";
import { StockCfaListComponent } from "./company/reports/stock-reports/stock-cfa-list/stock-cfa-list.component";
import { ViewManufacturerComponent } from "./company/manufacturer/view-manufacturer/view-manufacturer.component";
import { AddManufacturerComponent } from "./company/manufacturer/add-manufacturer/add-manufacturer.component";
import { UpdateManufacturerComponent } from "./company/manufacturer/update-manufacturer/update-manufacturer.component";
import { CustomerCareComponent } from "./company/customer-care/customer-care.component";
import { EditCustomerCareComponent } from "./company/edit-customer-care/edit-customer-care.component";
import { AddCustomerCareComponent } from "./components/add-customer-care/add-customer-care.component";
import { FstLvlInnerCodesComponent } from './components/fst-lvl-inner-codes/fst-lvl-inner-codes.component';
import { SndLvlInnerCodesComponent } from './components/snd-lvl-inner-codes/snd-lvl-inner-codes.component';
import { CodeGeneratedComponent } from './code_generated/code_generated.component';

//role 1 =>Admin, 2=>Company
const routes: Routes = [
  { path: "", redirectTo: "/companylogin", pathMatch: "full" },
  { path: "code-summary",
    component: CodeGeneratedComponent, 
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::Summary Of Qrcode",
    },
    pathMatch: "full" 
  },
  {
    path: "companylogin",
    component: CompanyloginComponent,
    pathMatch: "full",
    data: { title: "Trusttags::CompanyLogin", permissions: false },
  },
  {
    path: "sku",
    children: [
      {
        path: "",
        component: SkuComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        data: {
          title: "Trusttags::Sku",
        },
      },
      {
        path: "add",
        component: AddSkuComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::AddSku",
        },
      },
      {
        path: "edit/:id",
        component: EditSkuComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::EditSku",
        },
      },
      {
        path: "details/:id",
        component: SkudetailsComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::Sku Details",
        },
      },
      {
        path: "import-sku",
        component: ImportSkuComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::Import Sku",
        },
      },
    ],
  },
  {
    path: "batch",
    children: [
      {
        path: "",
        component: BatchlistComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::Batch",
        },
      },
    ],
  },
  {
    path: "manufacturer",
    children: [
      {
        path: "",
        component: ViewManufacturerComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::Manufacturer",
        },
      },
      {
        path: "add",
        component: AddManufacturerComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::Manufacturer",
        },
      },
      {
        path: "update/:id",
        component: UpdateManufacturerComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::Manufacturer",
        },
      },
    ],
  },
  {
    path: "user",
    children: [
      {
        path: "",
        component: UserComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::BusinessUser",
        },
      },
      {
        path: "add",
        component: AddUserComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::AddUser",
        },
      },
      {
        path: "edit/:id",
        component: EditUserComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::EditUser",
        },
      },
    ],
  },
  {
    path: "not_found",
    children: [
      {
        path: "",
        component: NotfoundComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Not Found", permissions: false },
      },
    ],
  },
  {
    path: "customer-care",
    children: [
      {
        path: "",
        component: CustomerCareComponent,
        canActivate: [AuthGuard],
        data: { title: "Trusttags::Customer Care" },
      },
      {
        path: "edit/:id",
        component: EditCustomerCareComponent,
        canActivate: [AuthGuard],
        data: { title: "Trusttags::Customer Care" },
      },
      {
        path: "add",
        component: AddCustomerCareComponent,
        canActivate: [AuthGuard],
        data: { title: "Trusttags::Customer Care" },
      },
    ],
  },
  {
    path: "qrcode",
    children: [
      {
        path: "",
        component: ListqrcodeComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::Qrcode",
        },
      },
      {
        path: "add",
        component: AddqrcodeComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::AddQrcode",
        },
      },
      {
        path: "details/:id",
        component: RoledetailsComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::Role Details",
        },
      },
    ],
  },{
    path: 'fst-level-inner-codes',
    component: FstLvlInnerCodesComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::First Level Inner Qrcode",
    }
  },{
    path: 'snd-level-inner-codes',
    component: SndLvlInnerCodesComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::Second Level Inner Qrcode",
    }
  },{
    path: "shipper_qrcode",
    children: [
      {
        path: "",
        component: ListshipperqrcodeComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        data: {
          title: "Trusttags::Shipper Qrcode",
        },
      },
      {
        path: "add",
        component: AddshipperqrcodeComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        data: {
          title: "Trusttags::Add Shipper Qrcode",
        },
      },
      {
        path: "details/:id",
        component: DetailshipperqrcodeComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::Detail Shipper Qrcode",
        },
      },
      {
        path: "import-shipper-qrcode",
        component: ImportshipperqrcodeComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::Import Shipper Qrcode",
        },
      },
    ],
  },
  {
    path: "stock-report",
    children: [
      {
        path: "",
        component: StockComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        data: {
          title: "Trusttags::Stock Report",
        },
      },
      {
        path: "details",
        component: StockDetailsComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        data: {
          title: "Trusttags::Stock Report Details",
        },
      },
      {
        path: "factory-list",
        component: StockCfaListComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
        data: {
          title: "Trusttags::Stock Report",
        },
      },
    ],
  },
  { path: "**", redirectTo: "companylogin", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
