import { Component, OnInit } from "@angular/core";
import { Globals } from 'src/app/globals';
declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  roleId;
  currentUserRole: any = localStorage.getItem("role_id");  
  
  constructor(public gloabl: Globals) {
    this.roleId = localStorage.getItem("role_id");     
  }

  ngOnInit() {}
}
