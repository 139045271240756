import { Component, OnInit } from "@angular/core";
import { Globals } from "src/app/globals";
import { ApiService } from "src/app/service/api-service/api-service.service";

@Component({
  selector: "app-stock-cfa-list",
  templateUrl: "./stock-cfa-list.component.html",
  styleUrls: ["./stock-cfa-list.component.css"],
})
export class StockCfaListComponent implements OnInit {
  cfaList = [];
  loader = true;
  searchText = "";
  searchTextFactory = "";
  sortedCollection: any[];
  p = this.globals.p;
  p1 = this.globals.p;
  currentLimit = this.globals.currentLimit;
  currentLimitFactory = this.globals.currentLimit;
  display: string = "none";
  pageLimits = this.globals.pageLimits;
  pageLimitsFactory = this.globals.pageLimits;

  displayFactoryList = true;

  factoryList = [];

  constructor(private globals: Globals, private apiService: ApiService) {}

  ngOnInit() {
    this.getFactoryList();
    this.getCFAList();
  }

  onChangeType(data) {
    if (data == "cfa") {
      this.searchText = "";
      this.displayFactoryList = false;
    } else {
      this.searchTextFactory = "";
      this.displayFactoryList = true;
    }
  }

  getFactoryList() {
    this.loader = true;
    this.apiService.get("company/factory/list", true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          this.factoryList = response.body.data;
        }
        this.loader = false;
      },
      (err) => {
        console.error(err);
        this.loader = false;
      }
    );
  }

  getCFAList() {
    this.loader = true;
    this.apiService.get("company/cfa/list?location=true", true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          this.cfaList = response.body.data;
        }
        this.loader = false;
      },
      (err) => {
        console.error(err);
        this.loader = false;
      }
    );
  }
}
