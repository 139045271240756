import { Injectable } from "@angular/core";

Injectable();
export class Globals {
  unitData = [
    {
      id: "",
      text: "",
    },
    {
      id: "1",
      text: "Kilogram",
    },
    {
      id: "2",
      text: "Gram",
    },
    {
      id: "3",
      text: "Ml",
    },
    {
      id: "4",
      text: "Liter",
    },
  ];

  pageLimits = [
    { id: 10, name: "10" },
    { id: 50, name: "50" },
    { id: 100, name: "100" },
    { id: 200, name: "200" },
  ];

  p: number = 1;
  currentLimit: any = 10;

  endCount(p, currentLimit, reportCount) {
    return Math.min(p * currentLimit, reportCount);
  }
  
}
