import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { companylogin } from "./../companylogin";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const TOKEN = "TOKEN";

@Injectable({
  providedIn: "root",
})
export class CompanyLoginService {
  apiURL: string = environment.apiURL + "companylogin/";

  constructor(private httpClient: HttpClient) {}

  public companylogin(f): Observable<companylogin> {
    return this.httpClient.post<companylogin>(`${this.apiURL}`, f);
  }
  logout() {
    localStorage.clear();
  }
}
