import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { ApiService } from "src/app/service/api-service/api-service.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-add-manufacturer",
  templateUrl: "./add-manufacturer.component.html",
  styleUrls: ["./add-manufacturer.component.css"],
})
export class AddManufacturerComponent implements OnInit {
  msg: string = null;
  loader = false;

  registerForm: FormGroup;
  submitted = false;

  stateList = [];
  cityList = [];
  mfgNames = ["M/s GSP Crop Science Pvt. Ltd.", "M/s Rajdhani Petrochemicals"];

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      phoneNo: ["", [Validators.required,  Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
      licenceNo: ["", [Validators.required]],
      address: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      uniqueName: ["", Validators.required],
    });

    this.getCountries();
  }

  get f() {
    return this.registerForm.controls;
  }

  onRegisterFormReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  onSave() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.loader = true;

    this.apiService
      .post(`company/manufacturer/add`, this.registerForm.value, true)
      .subscribe(
        (response: any) => {
          this.loader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.router.navigate(["./manufacturer"]);
          } else {
            this.msg = response.body.message;
          }
        },
        (error) => {
          console.error(error);
          this.loader = false;
          this.msg =
            "Due to server side issue you cannot add manufacturer details!";
        }
      );
  }

  getCountries() {
    this.apiService.get("company/country", true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          let indiaData = response.body.data.filter((country) => {
            return country.name == "India";
          });
          console.log("indiaData[0]", indiaData[0]);
          this.getStateList(indiaData[0].id);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getStateList(countryId) {
    this.apiService.get("company/state/" + countryId, true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          this.stateList = response.body.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  onSelectState(id) {
    this.getCityList(id.target.value);
  }

  getCityList(stateId) {
    this.apiService.get("company/city/" + stateId, true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          this.cityList = response.body.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
