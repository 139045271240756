import { Component } from '@angular/core';
declare var jQuery:any;
import { Router, Event, RouterEvent, NavigationStart, NavigationEnd, NavigationError,ActivatedRoute, NavigationCancel } from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent {

  title = 'trusttags-admin';
  isCompanyHeader : boolean =false;
  isLoginPage : boolean =false;
  loading:boolean =true;
  constructor(    
                private router: Router,
                private route: ActivatedRoute,
                private titleService: Title, 
                private meta: Meta) 
  {
  	 
     router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  ngOnInit() {

    
  	this.router.events.subscribe( (event: Event) => {

        if (event instanceof NavigationStart) {
        	console.log(event.url);
        	if ( event.url.includes("company/") ) {
              this.isCompanyHeader = true;
          } else {
            this.isCompanyHeader = false;
          }

          if ( event.url.includes("login") || event.url == '/' ) {
        		this.isLoginPage = true;
        	} else {
        		this.isLoginPage = false;
        	}
           
        }

       
        this.router.events
        .filter((event) => event instanceof NavigationEnd)
        .map(() => this.route)
        .map((route) => {
            while (route.firstChild) route = route.firstChild;
            return route;
        })
        .filter((route) => route.outlet === 'primary')
        .mergeMap((route) => route.data)
        .subscribe((event) => {
            this.updateDescription(event['description'], event['keywords'], event['title']);
        });
    });



  }


  updateDescription(desc: string, keywords: string, title: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'description', content: desc })
    this.meta.updateTag({ name: 'keywords', content: keywords })
    this.meta.updateTag({ name: 'og:title', content: title })
    this.meta.updateTag({ name: 'og:description', content: desc })
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      setTimeout(() => { // here
        this.loading = false;
      }, 500);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(() => { // here
        this.loading = false;
      }, 500);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => { // here
        this.loading = false;
      }, 500);
    }
  }
}
