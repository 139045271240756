import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { CompanyService } from "../../../company/service/company.service";
declare var $: any;
import { OrderPipe } from "ngx-order-pipe";
import { FlashMessagesService } from "angular2-flash-messages";
import { AngularCsv } from "angular7-csv/dist/Angular-csv";
import { Globals } from "./../../../globals";

@Component({
  selector: "app-batchlist",
  templateUrl: "./batchlist.component.html",
  styleUrls: ["./batchlist.component.css"],
})
export class BatchlistComponent implements OnInit {
  data: any;
  searchText = "";
  sortedCollection: any[];
  order: string = "result.id";
  reverse: boolean = false;
  result: any = [];
  arr: any = [];
  errors: any = "";
  deletedMsg: string = null;
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  deleteId: any;
  pageLimits = this.globals.pageLimits;
  skuData: any;
  sku_id: any;
  skus: any = [];
  csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    showTitle: false,
    title: "Qrcode List :",
    useBom: true,
    noDownload: false,
    headers: [], //headers: ["QrCode"]
  };
  loader: boolean = false;

  constructor(
    private globals: Globals,
    private _flashMessagesService: FlashMessagesService,
    public companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private orderPipe: OrderPipe
  ) {
    this.sortedCollection = orderPipe.transform(this.result, "batch_no");
    console.log(this.sortedCollection);
  }

  ngOnInit() {
    this.BatchList();
    this.getSkuData();
  }

  BatchList() {
    let id = "";
    this.loader = true;
    this.companyService.getBatchList(id).subscribe((res: any) => {
      this.loader = false;
      this.result = res.data;
      console.log(res);
    });
  }

  getSkUBatches(event) {
    let skuId = "";
    console.log(event);
    if (event.value != "1") {
      skuId = this.sku_id = event.value;

      this.companyService.getBatchList(skuId).subscribe((res: any) => {
        this.loader = false;
        this.result = res.data;
        console.log(res);
      });
    } else {
      this.companyService.getBatchList(skuId).subscribe((res: any) => {
        this.loader = false;
        this.result = res.data;
        console.log(res);
      });
    }
  }

  /**
   * [setOrder description]
   * @author Suresh Suthar
   * @Created Date     2019-03-22
   * @param   {string} value      [description]
   */
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  /**
   * [opendeleteModal description]
   * @author Suresh Suthar
   * @Created Date     2019-03-29
   * @param   {[type]} id         [description]
   */
  opendeleteModal(id) {
    this.deleteId = id;
    $("#exampleModal").modal("show");
  }

  /**
   * [batchDelete description]
   * @author Suresh Suthar
   * @Created Date 2019-03-29
   */
  batchDelete(batchId) {
    this.companyService.delete_batch(batchId).subscribe((res: any) => {
      if (res.success == 1) {
        this._flashMessagesService.show(res.message, {
          cssClass: "alert-success",
        });

        setTimeout(() => {
          this.router.navigate(["/batch"]);
        }, 3000);
      }
    });
  }

  /**
   * @author Umang Kothari
   */
  async getSkuData() {
    await this.companyService.product_list().subscribe((res: any) => {
      this.skuData = res.data;
      var rObj = [];
      this.skus.length = 0;
      console.log("---");
      rObj.push({ id: "1", text: "ALL" });
      for (let i = 0; i < res.data.length; i++) {
        var displaySKU = res.data[i].sku + " (" + res.data[i].name + ")";
        rObj.push({ id: res.data[i].id, text: displaySKU });
      }

      this.skus = rObj;
    });
  }
}
