import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { BusinessService } from "../../service/business.service";
import { FlashMessagesService } from "angular2-flash-messages";
import * as $ from "jquery";
import { OrderPipe } from "ngx-order-pipe";
import { ApiService } from "src/app/service/api-service/api-service.service";
import { Globals } from "src/app/globals";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.css"],
})
export class AddUserComponent implements OnInit {
  resultList: any;
  result: any;
  msg: string = null;
  errors: any = "";
  model: any = {};
  roleOptions: any;
  submit: boolean = false;
  parentOptions: any;
  parentList: any;

  manufacturerList = [];

  displayFactoryList = false;

  showLoader = false;

  loader = false;

  file: any;

  isConfigured = false;

  stateList = [];
  cityList = [];

  channelList = [];
  regionList = [];

  registerForm: FormGroup;
  submitted = false;

  isAddMultipleUsers = false;

  isUserCreated = false;
  errorMsg = null;

  uploadForm: FormGroup;
  uploadSubmitted = false;

  visibleFeatureList = false;
  featureList = [];
  factoryList = [];

  searchText = "";
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  pageLimits = this.globals.pageLimits;
  assignedFeatures: any = [];
  displayNext: boolean = false;

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  constructor(
    private globals: Globals,
    private _flashMessagesService: FlashMessagesService,
    public businessService: BusinessService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private orderPipe: OrderPipe,
    private apiService: ApiService
  ) {}

  async ngOnInit() {
    this.roleOptions = { placeholder: { id: "", text: "Select Role" } };
    this.parentOptions = { placeholder: { id: "", text: "Select Parent" } };

    this.registerForm = this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(100)]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      phoneNo: [
        "",
        [Validators.required, Validators.pattern("^(0)?[0-9]{10}$")],
      ],
      password: ["", [Validators.required, Validators.minLength(6)]],
      address: ["", Validators.required],
      role_id: ["", Validators.required],
    });

    this.getChannelList();
    this.getCountries();
    this.getManufacturerList();
  }

  getManufacturerList() {
    this.apiService.get(`company/manufacturer/all`, true).subscribe(
      (response: any) => {
        this.loader = false;
        if (response.status === 200 && response.body.success == 1) {
          this.manufacturerList = response.body.data;
        }
      },
      (err) => {
        this.loader = false;
      }
    );
  }

  getCountries() {
    this.apiService.get("company/country", true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          let indiaData = response.body.data.filter((country) => {
            return country.name == "India";
          });
          console.log("indiaData[0]", indiaData[0]);
          this.getStateList(indiaData[0].id);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getStateList(countryId) {
    this.apiService.get("company/state/" + countryId, true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          this.stateList = response.body.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  onSelectState(id) {
    this.getCityList(id.target.value);
  }

  getCityList(stateId) {
    this.apiService.get("company/city/" + stateId, true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          this.cityList = response.body.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getChannelList() {
    this.apiService.get("company/businessuser/channels/list", true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          this.channelList = response.body.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  get f() {
    return this.registerForm.controls;
  }

  onAddUser() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.loader = true;
    this.apiService
      .post(`company/businessuser`, this.registerForm.value, true)
      .subscribe(
        (response: any) => {
          this.loader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.onAddReset();
            this.isUserCreated = true;
            this.visibleFeatureList = false;
            window.location.href = "./user";
          } else {
            this.errorMsg = response.body.message;

            setTimeout(() => {
              this.errorMsg = null;
            }, 4000);
          }
        },
        (err) => {
          this.loader = false;
          this.errorMsg = "User has not created due server issue!";
          setTimeout(() => {
            this.errorMsg = null;
          }, 4000);
        }
      );
  }

  onAddReset() {
    this.registerForm.reset();
    this.submitted = false;
    this.displayNext = false;
    this.displayFactoryList = false;
    this.registerForm.removeControl("factory");
  }

  onChangeRole(e) {
    let name = this.channelList[e.target.selectedIndex - 1].name;
    if (name == "Admin" || name == "CFA" || name == "Factory") {
      if (name == "Factory") {
        this.displayFactoryList = true;
        this.registerForm.addControl(
          "manufacturer",
          new FormControl("", [Validators.required])
        );
      } else {
        this.registerForm.removeControl("manufacturer");
        this.displayFactoryList = false;
      }
    } else {
      this.displayFactoryList = false;
      this.registerForm.removeControl("manufacturer");
    }
  }
}
