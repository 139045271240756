import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "src/app/service/api-service/api-service.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-update-manufacturer",
  templateUrl: "./update-manufacturer.component.html",
  styleUrls: ["./update-manufacturer.component.css"],
})
export class UpdateManufacturerComponent implements OnInit {
  msg: string = null;
  loader = false;

  registerForm: FormGroup;
  submitted = false;

  stateList = [];
  cityList = [];

  Editid: any;

  mfgNames = ["M/s GSP Crop Science Pvt. Ltd.", "M/s Rajdhani Petrochemicals"];

  savedData: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      console.log(params);
      this.Editid = params["id"];
      this.getSavedDetails();
    });
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      id: [""],
      name: ["", [Validators.required]],
      phoneNo: ["", Validators.required],
      licenceNo: ["", [Validators.required]],
      address: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      uniqueName: ["", Validators.required],
    });

    this.getCountries();
  }

  getSavedDetails() {
    this.loader = true;
    this.apiService
      .get("company/manufacturer/details?id=" + this.Editid, true)
      .subscribe(
        (response: any) => {
          this.loader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.savedData = response.body.data;
            this.getCityList(this.savedData.state.id);
            this.registerForm.patchValue({
              id: this.Editid,
              name: this.savedData.name,
              uniqueName: this.savedData.unique_name,
              licenceNo: this.savedData.licence_no,
              address: this.savedData.address,
              phoneNo: this.savedData.phone_no,
              state: this.savedData.state.id,
              city: this.savedData.city.id,
            });
          }
        },
        (err) => {
          this.loader = false;
          console.error(err);
        }
      );
  }

  get f() {
    return this.registerForm.controls;
  }

  onRegisterFormReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  onSave() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.loader = true;

    this.apiService
      .put(`company/manufacturer`, this.registerForm.value, true)
      .subscribe(
        (response: any) => {
          this.loader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.router.navigate(["./manufacturer"]);
          } else {
            this.msg = response.body.message;
          }
        },
        (error) => {
          console.error(error);
          this.loader = false;
          this.msg =
            "Due to server side issue you cannot add manufacturer details!";
        }
      );
  }

  getCountries() {
    this.apiService.get("company/country", true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          let indiaData = response.body.data.filter((country) => {
            return country.name == "India";
          });
          console.log("indiaData[0]", indiaData[0]);
          this.getStateList(indiaData[0].id);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getStateList(countryId) {
    this.apiService.get("company/state/" + countryId, true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          this.stateList = response.body.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  onSelectState(id) {
    this.getCityList(id.target.value);
  }

  getCityList(stateId) {
    this.apiService.get("company/city/" + stateId, true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          this.cityList = response.body.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
