import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { CompanyService } from "../../../company/service/company.service";
declare var $: any;
import { OrderPipe } from "ngx-order-pipe";
import { FlashMessagesService } from "angular2-flash-messages";
import { AngularCsv } from "angular7-csv/dist/Angular-csv";
import { Globals } from "./../../../globals";
import * as XLSX from 'xlsx';
import * as moment from 'moment';

@Component({
  selector: "app-listqrcode",
  templateUrl: "./listqrcode.component.html",
  styleUrls: ["./listqrcode.component.css"],
})
export class ListqrcodeComponent implements OnInit {

  data: any;
  searchText = "";
  sortedCollection: any[];
  order: string = "createdAt";
  reverse: boolean = true;
  result: any = [{}];
  arr: any = [];
  errors: any = "";
  deletedMsg: string = null;
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  deleteId: any;
  pageLimits = this.globals.pageLimits;
  loader: boolean = false;
  endCount = this.globals.endCount;
  selectedQRCodeID;
  radioBtn;
  selectedSeparator;

  csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    showTitle: false,
    title: "Qrcode List :",
    useBom: true,
    noDownload: false,
    headers: ["QR Codes", "Batch No.", "Mfg. Date", "Exp. Date", "MRP", "Sub Code", "SKU"], //headers: ["QrCode"]
  };

  constructor(
    private _flashMessagesService: FlashMessagesService,
    public companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private orderPipe: OrderPipe,
    private globals: Globals
  ) {
    this.sortedCollection = this.orderPipe.transform(this.result, this.order);
    console.log(this.sortedCollection);
  }

  /**
   * [ngOnInit description]
   * @author Suresh Suthar
   * @Created Date 2019-04-05
   */
  ngOnInit() {
    this.loader = true;
    this.companyService.listQrcode().subscribe((res: any) => {
      this.loader = false;

      if (res.success == "1") {
        this.result = res.data;
        console.log(this.result);
      }
    });
  }

  async exportQrcode() {
    // moment(qrcodes[i].product_batch.mfgDate).format("DD-MMM-YY").toUpperCase(),
    this.loader = true;
    this.companyService.getSkuList(this.selectedQRCodeID).subscribe(async (res: any) => {
      this.loader = false;
      console.log(res.data);
      // return false;
      if (res.success == 1) {
        let qrCode = res.data;
        console.log(this.selectedSeparator)


        let formatter = this.radioBtn;
        
        if(this.selectedSeparator == '.') {
          formatter = formatter.replace(/-/g, ".")
        }

        let alteredData = await qrCode.map(item => {
          return {
            "QR Codes": item["QR Codes"]
              .replace("{{expDT}}", moment(item["Exp. Date"]).format(formatter).toUpperCase())
              .replace("{{mfgDT}}", moment(item["Mfg. Date"]).format(formatter).toUpperCase()),
            "Batch No.": item["Batch No."],
            "Mfg. Date": moment(item["Mfg. Date"]).format(formatter).toUpperCase(),
            "Exp. Date": moment(item["Exp. Date"]).format(formatter).toUpperCase(),
            "MRP": item.MRP,
            "Sub Codes": item['Sub Codes'],
            "SKU": item['SKU']
          }
        });
        let fileName = `${res.batchNo}_${res.sku} (product-codes).xlsx`;
        console.log(qrCode)
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(alteredData);
        ws['!cols'] = [{ width: 80 }, { width: 10 }, { width: 10 }, { width: 10 }, { width: 10 }, { width: 20 }, { width: 10 }]
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, fileName);

        // new AngularCsv(qrCode, fileName, this.csvOptions);
      } else {
        this._flashMessagesService.show(res.message, {
          cssClass: "alert-success",
        });

        setTimeout(() => {
          this.router.navigate(["/qrcode"]);
        }, 3000);
      }
    }, err => {
      this.loader = false;
    });
  }

  setOrder(value: string) {
    console.log("o: ", this.order, " v: ", value);
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  //   exportSKUcode(roleUniqueId){
  //   this.companyService.getSkuList(roleUniqueId).subscribe((res:any)=>{
  //     console.log(res);

  //     if(res.success==1){
  //           let qrCode = res.data;

  //           let random = Math.random().toString(36).substring(7);
  //           let fileName = roleUniqueId;
  //           new AngularCsv(qrCode, fileName, this.csvOptions);
  //     }
  //     else{
  //       this._flashMessagesService.show(res.message, { cssClass: 'alert-success' } );

  //       setTimeout(() => {
  //         this.router.navigate(['/qrcode']);
  //       }, 2000);
  //     }
  //   });
  // }

  // endCount(p,currentLimit,reportCount){

  //   return Math.min((p * currentLimit), reportCount);
  // }
}
