import { Component, OnInit } from '@angular/core';
import {Router, Route } from '@angular/router';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  /**
   * [backToHome description]
   * @author Suresh Suthar
   * @Created Date 2019-04-03
   */
  backToHome(){

  	 this.router.navigate(['/dashboard']);
  }

}
