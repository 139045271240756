import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const TOKEN = "TOKEN";

const httpOptions = {
  headers: new HttpHeaders({
    //'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': "*",
    // 'Access-Control-Allow-Methods' :"GET, POST, DELETE, PUT",
    // 'Access-Control-Allow-Headers' :"Origin, X-Requested-With, Content-Type, Accept",
    "x-key": localStorage.getItem("companyRandom_id"),
    "x-access-token": localStorage.getItem("access_token"),
  }),
};

@Injectable({
  providedIn: "root",
})
export class BusinessService {
  apiURL: string = environment.apiURL + "company/";
  apiURL2: string = environment.apiURL + "businessrole/";
  apiURL3: string = environment.apiURL + "businessuser/";

  constructor(private httpClient: HttpClient) {}

  businessRole_add(data) {
    return this.httpClient.post(
      `${this.apiURL}businessrole`,
      data,
      httpOptions
    );
  }

  businessRole_list() {
    return this.httpClient.get(`${this.apiURL}businessrole`, httpOptions);
  }

  deleteBusinessRole(id) {
    return this.httpClient.delete(`${this.apiURL2}${id}`, httpOptions);
  }

  businessRole_edit(id) {
    return this.httpClient.get(`${this.apiURL}businessrole/${id}`, httpOptions);
  }

  businessRole_update(id, data) {
    return this.httpClient.put(
      `${this.apiURL}businessrole/${id}`,
      data,
      httpOptions
    );
  }

  businessUser_add(data) {
    return this.httpClient.post(
      `${this.apiURL}businessuser`,
      data,
      httpOptions
    );
  }

  businessUser_list() {
    return this.httpClient.get(`${this.apiURL}businessuser`, httpOptions);
  }

  deleteBusinessUser(id) {
    return this.httpClient.delete(`${this.apiURL3}${id}`, httpOptions);
  }

  businessUser_edit(id) {
    return this.httpClient.get(`${this.apiURL}businessuser/${id}`, httpOptions);
  }

  businessUser_update(id, data) {
    return this.httpClient.put(
      `${this.apiURL}businessuser/${id}`,
      data,
      httpOptions
    );
  }

  //business parent role
  businessRole_parent(id) {
    return this.httpClient.get(
      `${this.apiURL}getParentUserByRoleId/${id}`,
      httpOptions
    );
  }

  //get role user parent
  businessRole_parentdata(role_id, user_id) {
    return this.httpClient.get(
      `${this.apiURL}getChildUserByRoleId/${role_id}/${user_id}`,
      httpOptions
    );
  }

  businessRole_parentdatadd(data) {
    return this.httpClient.post(
      `${this.apiURL}allocateDeallocateChildUsers`,
      data,
      httpOptions
    );
  }

  getBusinessConsignment(pageNo, pageSize) {
    return this.httpClient.get(
      `${this.apiURL}getBusinessConsignment/${pageNo}/${pageSize}`,
      httpOptions
    );
  }

  getBusinessConsignmentSKUDetails(id) {
    return this.httpClient.get(
      `${this.apiURL}getBusinessConsignmentSKUDetails/${id}`,
      httpOptions
    );
  }

  searchConsignment(pageNo, pageSize, data) {
    return this.httpClient.post(
      `${this.apiURL}getBusinessConsignmentSearch/${pageNo}/${pageSize}`,
      data,
      httpOptions
    );
  }
}
