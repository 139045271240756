import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
declare var $: any;
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { Globals } from './../../../globals';

@Component({
  selector: 'app-detailshipperqrcode',
  templateUrl: './detailshipperqrcode.component.html',
  styleUrls: ['./detailshipperqrcode.component.css']
})
export class DetailshipperqrcodeComponent implements OnInit {
    data: any;
    searchText = "";
    sortedCollection: any[];
    order: string = 'result.id';
    reverse: boolean = false;
    result: any = [];
    arr: any = [];
    errors: any = '';
    deletedMsg: string = null;
    p = this.globals.p;
    currentLimit = this.globals.currentLimit;
    deleteId: any;
    pageLimits = this.globals.pageLimits;
    loader: boolean = false;
    public shipperQrcodeId: string;
    noDataFound: boolean = false;

    constructor(private _flashMessagesService: FlashMessagesService,
        public companyService: CompanyService,
        private route: ActivatedRoute,
        private router: Router,
        private orderPipe: OrderPipe,
        private globals: Globals) {
        this.sortedCollection = orderPipe.transform(this.result, 'result.id');
    }

    async ngOnInit() {
        await this.route.params.subscribe(params => {
            this.shipperQrcodeId = params['id'];
        });

        this.loader = true;
        await this.companyService.getScannedShipperQrcodes(this.shipperQrcodeId).subscribe((res: any) => {
            this.loader = false;
            if (res.success == "1") {
                this.result = res.data;
                console.log(this.result);
            }
            else {
                this.noDataFound = true
            }
                    
        });
  }
    setOrder(value: string) {
        if (this.order === value) {
            this.reverse = !this.reverse;
        }

        this.order = value;
    }
}
