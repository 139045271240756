import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/service/api-service/api-service.service";
import { ActivatedRoute } from "@angular/router";
import { Globals } from "./../../../../globals";
import * as moment from "moment";
import { AngularCsv } from "angular7-csv/dist/Angular-csv";

@Component({
  selector: "app-stock-details",
  templateUrl: "./stock-details.component.html",
  styleUrls: ["./stock-details.component.css"],
})
export class StockDetailsComponent implements OnInit {
  stockList = [];
  skuId;
  binId;
  csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    showTitle: false,
    title: "Qrcode List :",
    useBom: true,
    noDownload: false,
    headers: ["Data", "Subcode"/*, "Batch No.", "Mfg. Date", "Exp. Date"*/], //headers: ["QrCode"]
  };
  // totalUnits = 0;
  loader = true;
  searchText = "";
  sortedCollection: any[];
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  display: string = "none";
  pageLimits = this.globals.pageLimits;
  id;
  identity;
  sku;
  bin;
  count;

  order;
  reverse: boolean = false;

  constructor(
    private apiService: ApiService,
    private globals: Globals,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams
      // .filter(params => params.sku)
      .subscribe((params) => {
        console.log(params); // {order: "popular"}
        this.identity = ["cfa", "factory"].includes(params["i"])
          ? params["i"]
          : null;          
        if (this.identity) {
          this.id = params.fId;
        }
        console.log(params)
        this.skuId = params.sku;
        this.sku = params.s;
        this.bin = params.b;
        this.count = params.c;
        this.binId = params.bin ? params.bin : null;
      });

    this.getStockReport();
  }

  getStockReport() {
    let qStr;
    if (this.binId) {
      qStr = `skuId=${this.skuId}&binId=${this.binId}`;
    } else {
      qStr = `skuId=${this.skuId}`;
    }

    if (this.identity) {
      qStr += `&identity=${this.identity}&id=${this.id}`;
    }

    let url = this.apiService
      .get(`company/stock-report/detailed?${qStr}`, true)
      .subscribe(
        (response: any) => {
          if (response.status == 200 && response.body.success == 1) {
            this.stockList = response.body.data;

            // for (let i = 0; i < this.stockList.length; i++) {
            //   this.totalUnits += Number(this.stockList[i].count);
            // }
          }
          this.loader = false;
        },
        (err) => {
          console.error(err);
          this.loader = false;
        }
      );
  }
  getQRCodes(batch_id) {
    this.loader = true;
    this.apiService.get(`company/stock-report/csv?id=${this.id}&product_id=${this.skuId}&batch_id=${batch_id}`, true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          let qrCode = [];
          qrCode = response.body.qrCodes.map((item) => {
            console.log(item)
            return {
              //Data: `UID ${item.qr_code} B.NO ${item.product_batch.batch_no} MFG DT ${moment(item.product_batch.manufacturing_date).format("DD-MMM-YY").toUpperCase()} EXP DT ${moment(item.product_batch.expiry_date).format("DD-MMM-YY").toUpperCase()} www.ttags.in/${item.qr_code}`,
              Data: `UID ${item.qr_code} B.NO ${item.product_batch.batch_no} MFG DT ${moment(new Date(item.product_batch.manufacturing_date)).format("DD-MMM-YY").toUpperCase()} EXP DT ${moment(new Date(item.product_batch.expiry_date)).format("DD-MMM-YY").toUpperCase()} www.ttags.in/${item.qr_code}`,
              Subcode: item.qr_code,
              //SKU: item.product.sku,
              //"Batch No.": item.product_batch.batch_no,
              /*"Mfg. Date": moment(
                new Date(item.product_batch.manufacturing_date)
              ).format("DD-MM-YYYY"),
              "Exp. Date": moment(
                new Date(item.product_batch.expiry_date)
              ).format("DD-MM-YYYY"),*/
            };
          });

          let fileName =
            response.body.qrCodes[0].product_batch.batch_no; + "_stock-report";
          new AngularCsv(qrCode, fileName, this.csvOptions);
          this.loader = false;
        }
      },
      (error) => {
        this.loader = false;
        console.log(error);
      }
    );
  }


  setOrder(value: string) {
    console.log(value);
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }


}
