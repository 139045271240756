import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../login/service/login.service';
import * as $ from "jquery";



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

	public companyName= localStorage.getItem('name');
  public role :any= localStorage.getItem('role_id');
  public adminId :any;
  public result:any;

  SyncAccess = false;
	
  constructor(private route: ActivatedRoute, private router: Router, public loginService:LoginService) { }

  ngOnInit() {
      this.adminId =  (localStorage.getItem('a_id')) ? localStorage.getItem('a_id') : "";        
  }
  

  Logout(){
    localStorage.clear();
    this.router.navigate(['/companylogin']); 
    // if(this.role == 1){
    // localStorage.clear();
    // this.router.navigate(['/login']);
    // }
    // else{
    //   localStorage.clear();
    //  this.router.navigate(['/companylogin']); 
    // }
  }

  /**
   * [switchToAdminAccount description]
   * @author Suresh Suthar
   * @Created Date 2019-04-02
   */
  switchToAdminAccount(){

      localStorage.clear();
      localStorage.setItem('id',"-1");
      this.loginService.adminLogin(this.adminId).subscribe((res:any)=>{

          if(res.success==1){

              this.result = res.data;
              localStorage.setItem('a_id', "");
              localStorage.setItem('id', this.result.id);
              localStorage.setItem('x-key', this.result.id);
              localStorage.setItem('role_id', this.result.role_id);
              localStorage.setItem('phone_number', this.result.phone_number);
              localStorage.setItem('status', this.result.status);
              localStorage.setItem('name', this.result.name);
              localStorage.setItem('username', this.result.username);
              localStorage.setItem('email', this.result.email);
              localStorage.setItem('main_image', this.result.main_image);
              localStorage.setItem('thumb_image', this.result.thumb_image);
              localStorage.setItem('is_deleted', this.result.is_deleted);
              localStorage.setItem('access_token', res.access_token);
              window.location.href="dashboard";
          }
      });
  }
}
