import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';

@Component({
  selector: 'app-importshipperqrcode',
  templateUrl: './importshipperqrcode.component.html',
  styleUrls: ['./importshipperqrcode.component.css']
})
export class ImportshipperqrcodeComponent implements OnInit {
    result: any;
    msg: string = null;
    selectedFile: string = '';
    errors: any = '';
    model: any = {};
    shipperqrcodecsv: any;
    message: any;
    filedata: any;
    submit: boolean = false;
    loader: boolean = false;

    csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'ImportCSVSample',
        useBom: true,
        noDownload: false,
        headers: ['code']
    };

    constructor(private _flashMessagesService: FlashMessagesService, public companyService: CompanyService, private route: ActivatedRoute, private router: Router) { }

    ngOnInit() {
    }

    onFileChange(e) {
        this.filedata = e.target.files[0];
        this.selectedFile = this.filedata.name;
        this.message = '';

        if (!this.filedata.name.match(/\.(csv)$/)) {
            this.message = "Invalid file format.";
        }
        console.log(this.filedata);
    }

    onSubmit(form?: NgForm) {
        this.loader = true;
        if (this.filedata == undefined && this.filedata == null) {
            this.loader = false;
            this.message = "CSV File is Required";
            return;
        }
        if (!this.filedata.name.match(/\.(csv)$/)) {
            this.loader = false;
            this.message = "Invalid file format.";
            return;
        }

        let f = form.value;
        let formdata = new FormData();
        formdata.append("csv", this.filedata);

        this.companyService.import_ShipperQrcode(formdata).subscribe((res: any) => {
            console.log(res);
            this.result = res;
            this.loader = false;
            if (this.result.success == 1) {
                this.submit = true;
                this._flashMessagesService.show('Shipper Qr Code imported successfully', { cssClass: 'alert-success' });
                setTimeout(() => {
                    this.router.navigate(['/shipper_qrcode']);
                }, 2000);

            } else {
                this.errors = res.message;
                setTimeout(() => {
                    this.errors.import_sku = false;
                }, 3000);
            }
        }, (err) => {
            console.log('subscribe thrown error');
        });
    }

    DownloadCSVSample() {
        let random = Math.random().toString(36).substring(7);
        new AngularCsv([], 'ImportShipperQrCodeCSVSample_' + random, this.csvOptions);
    }
}
