import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "src/app/service/api-service/api-service.service";

@Component({
  selector: "app-add-customer-care",
  templateUrl: "./add-customer-care.component.html",
  styleUrls: ["./add-customer-care.component.css"],
})
export class AddCustomerCareComponent implements OnInit {
  customerCareDetails: any = {};

  registerForm: FormGroup;
  submitted = false;

  loader = false;
  msg: String;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      marketedBy: ["", Validators.required],
      email: ["", [Validators.email, Validators.required]],
      phoneNo: ["", Validators.required,  Validators.maxLength(10), Validators.pattern("^[0-9]*$")],
      address: ["", Validators.required],
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  onSave() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.loader = true;

    this.apiService
      .post("company/customer-care/add", this.registerForm.value, true)
      .subscribe(
        (res: any) => {
          this.loader = false;
          if (res.status == 200 && res.body.success == 1) {
            this.router.navigate(["/customer-care"]);
          } else {
            this.msg = res.body.message;
            setTimeout(() => {
              this.msg = null;
            }, 7000);
          }
        },
        (err) => {
          this.loader = false;
          this.msg =
            "Due to internal server issue, you can't add customer care details!";
          setTimeout(() => {
            this.msg = null;
          }, 7000);
        }
      );
  }
}
