import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const TOKEN = 'TOKEN';

const httpOptions = {
  headers: new HttpHeaders({

	'x-key': localStorage.getItem('x-key'),
	'x-access-token':localStorage.getItem('access_token')
  })
};

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  apiURL: string = environment.apiURL + 'company/';

  constructor(private httpClient: HttpClient) {}

  company_add(data) {
    return this.httpClient.post(`${this.apiURL}`, data, httpOptions);
  }

  company_list() {
    return this.httpClient.get(`${this.apiURL}`, httpOptions);
  }

  deleteCompany(id) {
    return this.httpClient.delete(`${this.apiURL}${id}`, httpOptions);
  }

  company_local(id) {
    return this.httpClient.get(`${this.apiURL}${id}`, httpOptions);
  }

  company_edit(id) {
    return this.httpClient.get(`${this.apiURL}${id}`, httpOptions);
  }

  company_update(id, data) {
    return this.httpClient.put(`${this.apiURL}${id}`, data, httpOptions);
  }

 
  //Packaging
  packaging_add(data) {
    return this.httpClient.post(`${this.apiURL}packaging`, data, httpOptions);
  }

  packaging_list() {
    return this.httpClient.get(`${this.apiURL}packaging`, httpOptions);
  }

  deletePackaging(id) {
    return this.httpClient.delete(`${this.apiURL}packaging/${id}`, httpOptions);
  }

  packaging_edit(id) {
    return this.httpClient.get(`${this.apiURL}packaging/${id}`, httpOptions);
  }

  packaging_update(id, data) {
    return this.httpClient.put(`${this.apiURL}packaging/${id}`, data, httpOptions);
  }

  packaging_size(id) {
    return this.httpClient.get(`${this.apiURL}sizebyvarientid/${id}`, httpOptions);
  }

  //Product
  product_add(data) {
    return this.httpClient.post(`${this.apiURL}product`, data, httpOptions);
  }

  product_list() {
    return this.httpClient.get(`${this.apiURL}product`, httpOptions);
  }

  deleteProduct(id) {
    return this.httpClient.delete(`${this.apiURL}product/${id}`, httpOptions);
  }

  product_edit(id) {
    return this.httpClient.get(`${this.apiURL}product/${id}`, httpOptions);
  }

  product_update(id, data) {
    return this.httpClient.put(`${this.apiURL}product/${id}`, data, httpOptions);
  }

  product_packbysize(id) {
    return this.httpClient.get(`${this.apiURL}packagingbysizeid/${id}`, httpOptions);
  }

  //Shipper Code
  shipper_add(data) {
    return this.httpClient.post(`${this.apiURL}shipperqrcode`, data, httpOptions);
  }
  shipper_list() {
    return this.httpClient.get(`${this.apiURL}shipperqrcode`, httpOptions);
  }

  deleteShipper(id) {
    return this.httpClient.delete(`${this.apiURL}shipperqrcode/${id}`, httpOptions);
  }

  shipper_edit(id) {
    return this.httpClient.get(`${this.apiURL}shipperqrcode/${id}`, httpOptions);
  }

  shipper_update(id, data) {
    return this.httpClient.put(`${this.apiURL}shipperqrcode/${id}`, data, httpOptions);
  }

  country_list() {
    return this.httpClient.get(`${this.apiURL}country`, httpOptions);
  }

  state_list(countryid) {
    return this.httpClient.get(`${this.apiURL}state/${countryid}`, httpOptions);
  }

  city_list(stateid) {
    return this.httpClient.get(`${this.apiURL}city/${stateid}`, httpOptions);
  }

  //Employee User Api Start

  employee_add(data) {
    return this.httpClient.post(`${this.apiURL}employee`, data, httpOptions);
  }

  employee_list() {
    return this.httpClient.get(`${this.apiURL}employee`, httpOptions);
  }

  deleteEmployee(id) {
    return this.httpClient.delete(`${this.apiURL}employee/${id}`, httpOptions);
  }

  employee_edit(id) {
    return this.httpClient.get(`${this.apiURL}employee/${id}`, httpOptions);
  }

  employee_update(id, data) {
    return this.httpClient.put(`${this.apiURL}employee/${id}`, data, httpOptions);
  }

  //Company Role Api Start

  companyrole_add(data) {
    return this.httpClient.post(`${this.apiURL}companyrole`, data, httpOptions);
  }

  companyrole_list() {
    return this.httpClient.get(`${this.apiURL}companyrole`, httpOptions);
  }

  deleteCompanyrole(id) {
    return this.httpClient.delete(`${this.apiURL}companyrole/${id}`, httpOptions);
  }

  companyrole_edit(id) {
    return this.httpClient.get(`${this.apiURL}companyrole/${id}`, httpOptions);
  }

  companyrole_update(id, data) {
    return this.httpClient.put(`${this.apiURL}companyrole/${id}`, data, httpOptions);
  }

  //Company User Api Start

  companyuser_add(data) {
    return this.httpClient.post(`${this.apiURL}companyuser`, data, httpOptions);
  }

  companyuser_list() {
    return this.httpClient.get(`${this.apiURL}companyuser`, httpOptions);
  }

  deleteCompanyuser(id) {
    return this.httpClient.delete(`${this.apiURL}companyuser/${id}`, httpOptions);
  }

  companyuser_edit(id) {
    return this.httpClient.get(`${this.apiURL}companyuser/${id}`, httpOptions);
  }

  companyuser_update(id, data) {
    return this.httpClient.put(`${this.apiURL}companyuser/${id}`, data, httpOptions);
  }

  //Sku Details Data Api
  productDetails_edit(id) {
    return this.httpClient.get(`${this.apiURL}productdetail/${id}`, httpOptions);
  }
  productDetails_add(id, data) {
    return this.httpClient.put(`${this.apiURL}productdetail/${id}`, data, httpOptions);
  }
  productDetails(data) {
    return this.httpClient.post(`${this.apiURL}productdetail`, data, httpOptions);
  }

  //Sku Details Data Api
  howtouse_edit(id) {
    return this.httpClient.get(`${this.apiURL}productdetail/${id}`, httpOptions);
  }
  howtouse_add(data) {
    return this.httpClient.post(`${this.apiURL}productdetail`, data, httpOptions);
  }
  //product details by id
  productbyId_details(id) {
    return this.httpClient.get(`${this.apiURL}productbyid/${id}`, httpOptions);
  }

  /**
   * [getBatchList description]
   * @author Suresh Suthar
   * @Created Date 2019-03-21
   */
  getBatchList(id?) {
    return this.httpClient.get(`${this.apiURL}product_batch/${id}`, httpOptions);
  }
  /**
   * [batch_add description]
   * @author Suresh Suthar
   * @Created Date     2019-03-29
   * @param   {[type]} data       [description]
   */
  batch_add(data) {
    return this.httpClient.post(`${this.apiURL}product_batch`, data, httpOptions);
  }

  /**
   * [getBatchDetails description]
   * @author Suresh Suthar
   * @Created Date 2019-03-29
   */
  getBatchDetails(id) {
    return this.httpClient.get(`${this.apiURL}product_batch/${id}`, httpOptions);
  }

  /**
   * [update_batch description]
   * @author Suresh Suthar
   * @Created Date     2019-03-29
   * @param   {[type]} id         [description]
   * @param   {[type]} data       [description]
   */
  update_batch(id, data) {
    return this.httpClient.put(`${this.apiURL}product_batch/${id}`, data, httpOptions);
  }

  /**
   * [getQrcodeList description]
   * @author Suresh Suthar
   * @Created Date 2019-03-29
   */
  getQrcodeList(id) {
    return this.httpClient.get(`${this.apiURL}qrcoderole/qrcode/${id}`, httpOptions);
  }

  /**
   * [delete_batch description]
   * @author Suresh Suthar
   * @Created Date 2019-03-29
   */
  delete_batch(id) {
    return this.httpClient.delete(`${this.apiURL}product_batch/${id}`, httpOptions);
  }

  /**
   * [createSKUQrcode description]
   * @author Suresh Suthar
   * @Created Date     2019-04-04
   * @param   {[type]} data       [description]
   */
  createSKUQrcode(data) {
    return this.httpClient.post(`${this.apiURL}qrcoderole`, data, httpOptions);
  }

  /**
   * [getSkURoles description]
   * @author Suresh Suthar
   * @Created Date     2019-04-05
   * @param   {[type]} id         [description]
   */
  getSkURoles(id) {
    return this.httpClient.get(`${this.apiURL}qrcoderole/product_role/${id}`, httpOptions);
  }

  /**
   * [getSkURoles description]
   * @author Umang Kothari
   * @Created Date 2019-08-09
   * @param   {[type]} id         [description]
   */
  getSkUStartSubCode(id) {
    return this.httpClient.get(`${this.apiURL}qrcoderole/product_subcode/${id}`, httpOptions);
  }

  /**
   * [listQrcode description]
   * @author Suresh Suthar
   * @Created Date 2019-04-05
   */
  listQrcode() {
    //return this.httpClient.get(`${this.apiURL}qrcoderole/`,httpOptions);
    return this.httpClient.get(`${this.apiURL}trustedqrcodeparent/`, httpOptions);
  }

  detailsQrcode(id) {
    return this.httpClient.get(`${this.apiURL}getroles/${id}`, httpOptions);
  }

  /**
   * [createPackagingQrcode description]
   * @author Suresh Suthar
   * @Created Date 2019-04-09
   */
  createPackagingQrcode(data) {
    return this.httpClient.post(`${this.apiURL}packaging_qrcode`, data, httpOptions);
  }

  /**
   * [listPackagingQrcode description]
   * @author Suresh Suthar
   * @Created Date     2019-04-09
   * @param   {[type]} data       [description]
   */
  listPackagingQrcode() {
    //return this.httpClient.get(`${this.apiURL}packaging_qrcode`,httpOptions);
    return this.httpClient.get(`${this.apiURL}packagingqrcodewithcount`, httpOptions);
  }

  /**
   * [getPackagingQrcodeList description]
   * @author Suresh Suthar
   * @Created Date     2019-04-09
   * @param   {[type]} id         [description]
   */
  getPackagingQrcodeList(id) {
    return this.httpClient.get(`${this.apiURL}packaging_qrcode/${id}`, httpOptions);
  }

  /**
   * [createShipperQrcode description]
   * @author Suresh Suthar
   * @Created Date     2019-04-09
   * @param   {[type]} data       [description]
   */
  createShipperQrcode(data) {
    return this.httpClient.post(`${this.apiURL}shipper_qrcode`, data, httpOptions);
  }

  /**
   * [listShipperQrcode description]
   * @author Suresh Suthar
   * @Created Date 2019-04-09
   */
  listShipperQrcode() {
    return this.httpClient.get(`${this.apiURL}shipper_qrcode`, httpOptions);
  }

  getShipperQrCodeWithCount() {
    return this.httpClient.get(`${this.apiURL}shipperqrcodewithcount`, httpOptions);
  }
  getScannedShipperQrcodes(id) {
    return this.httpClient.get(`${this.apiURL}getScannedShipperQrcodes/${id}`, httpOptions);
  }

  getScannedPackagingQrcodes(id) {
    return this.httpClient.get(`${this.apiURL}getScannedPackagingQrcodes/${id}`, httpOptions);
  }

  /**
   * [getShipperQrcodeList description]
   * @author Suresh Suthar
   * @Created Date     2019-04-09
   * @param   {[type]} id         [description]
   */
  getShipperQrcodeList(id) {
    return this.httpClient.get(`${this.apiURL}shipper_qrcode/${id}`, httpOptions);
  }

  /**
   * [getBatchAllDetails description]
   * @author Suresh Suthar
   * @Created Date 2019-04-11
   */
  getBatchAllDetails(id) {
    return this.httpClient.get(`${this.apiURL}product_batch/all_details/${id}`, httpOptions);
  }

  /**
   * [getBatchQRCode description]
   * @author Suresh Suthar
   * @Created Date     2019-04-12
   * @param   {[type]} id         [description]
   */
  getBatchQRCode(id) {
    return this.httpClient.get(`${this.apiURL}qrcoderole/all_qrcode/${id}`, httpOptions);
  }

  /**
   * [inventoryList description]
   * @author Suresh Suthar
   * @Created Date 2019-04-22
   */
  inventoryList() {
    return this.httpClient.get(`${this.apiURL}inventory`, httpOptions);
  }

  /**
   * [getSKUBatchStock description]
   * @author Suresh Suthar
   * @Created Date 2019-04-24
   */
  getSKUBatchStock(id) {
    return this.httpClient.get(`${this.apiURL}inventory/batch/${id}`, httpOptions);
  }

  trackandtrace_list() {
    return this.httpClient.get(`${this.apiURL}traceshipper`, httpOptions);
  }

  supplychainRoleList() {
    return this.httpClient.get(`${this.apiURL}roleList`, httpOptions);
  }

  distriList() {
    return this.httpClient.get(`${this.apiURL}getDistributions`, httpOptions);
  }

  //Supply Chain Service Start
  getParentBasedRole(id) {
    return this.httpClient.get(`${this.apiURL}getParentByRoleParentIds/${id}`, httpOptions);
  }

  supplychain_add(data) {
    return this.httpClient.post(`${this.apiURL}addChainUnit`, data, httpOptions);
  }
  supplychain_edit(id) {
    return this.httpClient.get(`${this.apiURL}editChainUnit/${id}`, httpOptions);
  }
  supplychain_update(id, data) {
    return this.httpClient.put(`${this.apiURL}updateChainUnit/${id}`, data, httpOptions);
  }
  //Supply Chain Service Finish

  import_SKU(data) {
    return this.httpClient.post(`${this.apiURL}importSKU`, data, httpOptions);
  }

  import_ShipperQrcode(data) {
    return this.httpClient.post(`${this.apiURL}importShipperQrcode`, data, httpOptions);
  }

  getMenus() {
    return this.httpClient.get(`${this.apiURL}menu`, httpOptions);
  }
  addMenu(data) {
    return this.httpClient.post(`${this.apiURL}menu`, data, httpOptions);
  }
  editMenu(id) {
    return this.httpClient.get(`${this.apiURL}menu/${id}`, httpOptions);
  }
  updateMenu(id, data) {
    return this.httpClient.put(`${this.apiURL}menu/${id}`, data, httpOptions);
  }
  deleteMenu(id) {
    return this.httpClient.delete(`${this.apiURL}menu/${id}`, httpOptions);
  }
  getNonParentMenus() {
    return this.httpClient.get(`${this.apiURL}getNonParentMenus`, httpOptions);
  }

  getRoleMenuPermission(id) {
    return this.httpClient.get(`${this.apiURL}rolemenupermissions/${id}`, httpOptions);
  }
  addRoleMenuPermission(data) {
    return this.httpClient.post(`${this.apiURL}rolemenupermissions`, data, httpOptions);
  }
  editRoleMenuPermission(id) {
    return this.httpClient.get(`${this.apiURL}editRoleMenuPermission/${id}`, httpOptions);
  }
  updateRoleMenuPermission(id, data) {
    return this.httpClient.put(`${this.apiURL}rolemenupermissions/${id}`, data, httpOptions);
  }
  deleteRoleMenuPermission(id) {
    return this.httpClient.delete(`${this.apiURL}rolemenupermissions/${id}`, httpOptions);
  }

  getconsignmentDetais(id1, id2) {
    return this.httpClient.get(`${this.apiURL}getBusinessConsignmentShippers/${id1}/${id2}`, httpOptions);
  }

  getSKUBatchData(id) {
    return this.httpClient.get(`${this.apiURL}product_batch/batchbysku/${id}`, httpOptions);
  }

  getSkUExpiryDate(id) {
    return this.httpClient.get(`${this.apiURL}product_batch/expDateBySKU/${id}`, httpOptions);
  }

  //Sku qrcode download
  getSkuList(id) {
    return this.httpClient.get(`${this.apiURL}qrcoderole/parentQrcode/${id}`, httpOptions);
  }

  // GRAPH API START
  getAgewiseData() {
    return this.httpClient.get(`${this.apiURL}agewiseData/`, httpOptions);
  }

  productionchart(param) {
    return this.httpClient.get(`${this.apiURL}productionchart${param}`, httpOptions);
  }

  monthlyshortage(param) {
    return this.httpClient.get(`${this.apiURL}monthlyShortage${param}`, httpOptions);
  }

  monthlydamage(param) {
    return this.httpClient.get(`${this.apiURL}monthlyDamage${param}`, httpOptions);
  }

  supplychain(param) {
    return this.httpClient.get(`${this.apiURL}supplychainslipage${param}`, httpOptions);
  }

  weeklyconsumption() {
    return this.httpClient.get(`${this.apiURL}weeklyconsumption/`, httpOptions);
  }

  brandbasedchart() {
    return this.httpClient.get(`${this.apiURL}monthlyscan/`, httpOptions);
  }

  geofacing() {
    return this.httpClient.get(`${this.apiURL}geofencing/`, httpOptions);
  }

  dailyScan() {
    return this.httpClient.get(`${this.apiURL}dailyScan/`, httpOptions);
  }

  brandScan() {
    return this.httpClient.get(`${this.apiURL}brandScan/`, httpOptions);
  }

  // GRAPH API ENDS

  registerByApp() {
    return this.httpClient.get(`${this.apiURL}customerProductRegister`, httpOptions);
  }

  scanByApp() {
    return this.httpClient.get(`${this.apiURL}customerProductscan`, httpOptions);
  }
  codelist(param) {
    return this.httpClient.get(`${this.apiURL}codes-summary${param}`, httpOptions);
  }
}

	


