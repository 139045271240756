import { Injectable } from "@angular/core";
import { LoginService } from "./../login/service/login.service";
import { CompanyLoginService } from "./../companylogin/service/companylogin.service";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Route,
  ActivatedRoute,
} from "@angular/router";
import 'rxjs/add/operator/do';
import { Observable } from "rxjs";
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';

@Injectable()
export class AuthGuard implements CanActivate, HttpInterceptor {
  constructor(
    private router: Router,
    private _loginService: LoginService,
    private companyloginService: CompanyLoginService
  ) {}
 
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return next.handle(request).do((event: HttpEvent<any>) => {
      console.log('api called')
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      console.log('api called')
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          localStorage.clear();
          this.router.navigate(["./companylogin"]);
          // redirect to the login route
          // or show a modal
        }
      }
    });
  }

  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log("url->", state.url);
    // console.log("id::", next.queryParams.id);

    let currentUserId = localStorage.getItem("id");

    if (currentUserId == undefined) {
      this.router.navigate(["./companylogin"]);
    } else if (next.data.permissions == false) {
      return true;
    } else {
      if (     
        state.url.toString().split("?")[0] == "./stock-report" &&
        next.queryParams.id == undefined
      ) {
        console.log(true);
        this.router.navigate(["./stock-report/factory-list"]);
      } else {
        return true;
      }
    }

    // let res: boolean = false;

    // let role = localStorage.getItem("role_id");
    // if (
    //   role == "1" &&
    //   state.url.toString().split("?")[0] == "./stock-report" &&
    //   next.queryParams.id == undefined
    // ) {
    //   console.log(true);
    //   this.router.navigate(["./stock-report/factory-list"]);
    // }

    // if (currentUserId != undefined && currentUserId != null) {
    //   if (typeof next.data.role != "undefined") {
    //     return true;
    //     // if(next.data.role.indexOf(role)!==-1){
    //     // 	return  true;
    //     // }
    //     // else{
    //     // 	this.router.navigate(['/not_found']);
    //     // }
    //   } else {
    //     return true;
    //   }
    // } else {
    //   this.router.navigate(["./companylogin"]);
    // }
  }
}
