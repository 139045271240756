import { Component, OnInit } from "@angular/core";
import { companylogin } from "./companylogin";
import { CompanyLoginService } from "./service/companylogin.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";
import * as $ from "jquery";
import { FlashMessagesService } from "angular2-flash-messages";
declare var Swiper: any; // not required
import {
  SwiperComponent,
  SwiperDirective,
  SwiperConfigInterface,
  SwiperScrollbarInterface,
  SwiperPaginationInterface,
} from "ngx-swiper-wrapper";

@Component({
  selector: "app-companylogin",
  templateUrl: "./companylogin.component.html",
  styleUrls: ["./companylogin.component.css"],
})
export class CompanyloginComponent implements OnInit {
  model: any = {};
  result: any = {};
  errors: any = "";
  loader: boolean = false;
  currentUserId: any = localStorage.getItem("id");

  public images = [
    {
      src: "../assets/images/industry-3087393_1920.jpg",
    },
  ];
  public config: SwiperConfigInterface = {
    a11y: true,
    direction: "horizontal",
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    pagination: true,
    autoplay: true,
    speed: 800,
    //navigation: true,
  };

  private scrollbar: SwiperScrollbarInterface = {
    el: ".swiper-scrollbar",
    hide: false,
    draggable: true,
  };

  private pagination: SwiperPaginationInterface = {
    el: ".swiper-pagination",
    clickable: true,
    hideOnClick: false,
  };

  constructor(
    private _flashMessagesService: FlashMessagesService,
    public companyloginService: CompanyLoginService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    console.log("+");
    if (this.currentUserId != undefined && this.currentUserId != null) {
      this.router.navigate(["/sku"]);
    }
  }

  async onSubmit(form?: NgForm) {
    let f = form.value;

    this.companyloginService.companylogin(f).subscribe(
      (res: any) => {
        // console.log(res);
        console.log("res.body.featureList: ", res.featureList);
        this.result = res.data;
        console.log(this.result);
        if (res["success"] == 1) {
          localStorage.setItem("id", this.result.id);
          localStorage.setItem("companyRandom_id", this.result.random_id);
          localStorage.setItem("x-key", this.result.random_id);
          localStorage.setItem("phone_number", this.result.contact1);
          localStorage.setItem("status", this.result.status);
          localStorage.setItem("name", this.result.first_name);
          localStorage.setItem("access_token", res.access_token);
          localStorage.setItem("role_id", this.result.role_id);
          // localStorage.setItem("fa", JSON.stringify(res.featureList));
          localStorage.setItem('rURL', res.redirectURL)
          let redirectURL = res.redirectURL;            
/*
          for (let i = 0; i < res.featureList.length && abort; i++) {
            if (
              res.featureList[i].tag === 0 &&
              res.featureList.can_read == true
            ) {
              redirectURL = res.featureList[i].router_link;
              abort = false;
            } else if (
              res.featureList[i].tag === 1 &&
              res.featureList[i].features.length > 0
            ) {
              for (
                let j = 0;
                j < res.featureList[i].features.length && abort;
                j++
              ) {
                if (
                  res.featureList[i].features[j].tag === 0 &&
                  res.featureList[i].features[j].can_read == true
                ) {
                  redirectURL = res.featureList[i].features[j].router_link;
                  abort = false;
                } else if (
                  res.featureList[i].features[j].tag === 1 &&
                  res.featureList[i].features[j].features.length > 0
                ) {
                  for (
                    let k = 0;
                    k < res.featureList[i].features[j].features.length && abort;
                    k++
                  ) {
                    if (
                      res.featureList[i].features[j].features[k].tag === 0 &&
                      res.featureList[i].features[j].features[k].can_read ==
                        true
                    ) {
                      redirectURL =
                        res.featureList[i].features[j].features[k].router_link;
                      abort = false;
                    } else if (
                      res.featureList[i].features[j].features[k].tag == 1 &&
                      res.featureList[i].features[j].features[k].features
                        .length > 0
                    ) {
                      for (let l=0; l< res.featureList[i].features[j].features[k].features.length; l++){
                        if(res.featureList[i].features[j].features[k].features[l].tag == 0 && res.featureList[i].features[j].features[k].features[l].can_read == true) {
                          redirectURL = res.featureList[i].features[j].features[k].features[l].router_link;
                          abort = false;
                        }
                      }
                    }
                  }
                }
              }
            }
            // if(res.featureList[i].can_read === true) {
            //   redirectURL = res.featureList[i].router_link;
            //   break;
            // }
          } */
          
          // window.location.href = redirectURL;
          window.location.href = "./sku";
          //this.router.navigate(['/dashboard']);
        } else {
          this.errors = res["message"];
          //    setTimeout(() => {
          //   this.errors = false;
          // }, 2000);

          //this._flashMessagesService.show(res['message'], { cssClass: 'alert-danger',timeout: 1000});
        }
      },
      (err) => {}
    );

    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.model))
  }
}
