import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BusinessService } from "../../service/business.service";
import { OrderPipe } from "ngx-order-pipe";
import {
  NgForm,
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { FlashMessagesService } from "angular2-flash-messages";
import { ApiService } from "src/app/service/api-service/api-service.service";

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.css"],
})
export class EditUserComponent implements OnInit {
  result: any = [];
  resultList: any;
  errorMsg: any = "";
  Editid: any;
  resultData: any;
  msg: string = null;
  public message: string;
  roleId: number;
  submit: boolean = false;
  loader: boolean = false;
  parentList: any;
  parentOptions: any;
  monthValidation = false;

  registerForm: FormGroup;
  submitted = false;

  manufacturerList = [];

  constructor(
    private _flashMessagesService: FlashMessagesService,
    public businessService: BusinessService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private orderPipe: OrderPipe,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      // console.log(params);
      this.Editid = params["id"];
      this.editUserData();
      this.parentOptions = { placeholder: { id: "", text: "Select Parent" } };
    });

    this.registerForm = this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(100)]],
      email: ["", [Validators.required, Validators.email]],
      phoneNo: [
        "",
        [Validators.required, Validators.pattern("^(0)?[0-9]{10}$")],
      ],
      password: ["", [Validators.minLength(6)]],
      address: ["", Validators.required],
      channelName: ["", Validators.required],
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  getManufacturerList() {
    this.apiService.get(`company/manufacturer/all`, true).subscribe(
      (response: any) => {
        this.loader = false;
        if (response.status === 200 && response.body.success == 1) {
          this.manufacturerList = response.body.data;
        }
      },
      (err) => {
        this.loader = false;
      }
    );
  }

  editUserData() {
    this.loader = true;
    this.businessService.businessUser_edit(this.Editid).subscribe(
      (res: any) => {
        console.log(res.data);
        this.loader = false;
        this.roleId = res.data.role_id;
        this.registerForm.patchValue({
          name: res.data.first_name,
          phoneNo: res.data.mobile_no,
          password: res.data.password,
          address: res.data.address,
          channelName: res.data.role_id == 1 ? "Admin" : "Factory",
          email: res.data.email,
        });

        if (res.data.role_id == 2) {
          this.registerForm.addControl(
            "manufacturer",
            new FormControl("", [Validators.required])
          );
          this.getManufacturerList();

          this.registerForm.patchValue({
            manufacturer: res.data["manufacturer.id"],
          });
        }
      },
      (err) => {
        this.loader = false;
        console.error(err);
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.loader = true;

    this.businessService
      .businessUser_update(this.Editid, {
        password: this.registerForm.value.password,
        email: this.registerForm.value.email,
        phoneNo: this.registerForm.value.phoneNo,
        name: this.registerForm.value.name,
        address: this.registerForm.value.address,
        // manufacturer: this.registerForm.value.manufacturer,
        roleId: this.roleId,
      })
      .subscribe(
        (res: any) => {
          this.loader = false;          
          if (res.success == 1) {            
              this.router.navigate(["/user"]);            
          } else {
            this.errorMsg = res.message;
          }
        },
        (err) => {
          this.errorMsg = "Due to internal server error you can't update details!";
          this.loader = false;
        }
      );
  }
}
