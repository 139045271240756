import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Login } from "./../login";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";

const TOKEN = "TOKEN";
const httpOptions = {
  headers: new HttpHeaders({
    "x-key": localStorage.getItem("x-key") ? localStorage.getItem("x-key") : "",
    "x-access-token": localStorage.getItem("access_token")
      ? localStorage.getItem("access_token")
      : ""
  })
};

@Injectable({
  providedIn: "root"
})
export class LoginService {
  apiURL: string = environment.apiURL + "login/";
  companyApiUrl: string = environment.apiURL + "company/";

  constructor(private httpClient: HttpClient) {}

  public login(f): Observable<Login> {
    return this.httpClient.post<Login>(`${this.apiURL}`, f);
  }

  logout() {
    localStorage.clear();
  }

  /**
   * [adminLogin description]
   * @author Suresh Suthar
   * @Created Date     2019-04-02
   * @param   {[type]} id         [description]
   */
  public adminLogin(id) {
    return this.httpClient.get(
      `${this.companyApiUrl}login_user/${id}`,
      httpOptions
    );
  }
}
