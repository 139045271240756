import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { CompanyService } from "../../../company/service/company.service";
declare var $: any;
import { OrderPipe } from "ngx-order-pipe";
import { FlashMessagesService } from "angular2-flash-messages";
import { AngularCsv } from "angular7-csv/dist/Angular-csv";
import { Globals } from "./../../../globals";
import { ApiService } from "src/app/service/api-service/api-service.service";
import * as moment from "moment";

//import * as jsPDF from 'jspdf'

@Component({
  selector: "app-listshipperqrcode",
  templateUrl: "./listshipperqrcode.component.html",
  styleUrls: ["./listshipperqrcode.component.css"],
})
export class ListshipperqrcodeComponent implements OnInit {
  data: any;
  searchText = "";
  sortedCollection: any[];
  order: string = "createdAt";
  reverse: boolean = true;
  result: any = [{}];
  arr: any = [];
  errors: any = "";
  deletedMsg: string = null;
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  deleteId: any;
  pageLimits = this.globals.pageLimits;
  loader: boolean = false;
  item: any = [];

  csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    showTitle: false,
    title: "Qrcode List :",
    useBom: true,
    noDownload: false,
    headers: ['QR Codes', 'Batch No.', 'Mfg. Date', 'Exp. Date', 'MRP', 'Sub Codes', 'SKU', 'Quantity']
    // headers: ["Data", "SKU", "Batch No.", "Mfg. Date", "Exp. Date", "Quantity", "Subcode"], //headers: ["QrCode"]
  };


  // componentAccess = {
  //   can_read: false,
  //   can_write: false,
  //   can_update: false,
  //   can_delete: false,
  // };

  constructor(
    private _flashMessagesService: FlashMessagesService,
    public companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private orderPipe: OrderPipe,
    private globals: Globals,
    private apiService: ApiService
  ) {
    this.sortedCollection = orderPipe.transform(this.result, this.order);
    // let f = JSON.parse(localStorage.getItem("fa")).filter((item) => {
    //   return item.name == "Shipper Codes";
    // });
    // f = f[0];
    // if (f) {
    //   this.componentAccess.can_read = f.can_read;
    //   this.componentAccess.can_write = f.can_write;
    //   this.componentAccess.can_update = f.can_update;
    //   this.componentAccess.can_delete = f.can_delete;
    //   console.log(f);
    // }
  }

  ngOnInit() {
    this.loader = true;
    this.companyService.getShipperQrCodeWithCount().subscribe((res: any) => {
      this.loader = false;
      if (res.success == "1") {
        console.log(res.data);
        let t = res.data.map((item) => {
          item.total_qrcode = Number(item.total_qrcode);
          item.scanned_count = Number(item.scanned_count);
          item.unscanned_count = Number(item.unscanned_count);
          return item;
        });
        this.result = t;
      }
    });
  }

  getQRCodes(id) {
    this.loader = true;
    this.apiService.get(`company/shipper-qrcode-list/${id}`, true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {

          // let qty = null;
          // let data = response.body.data; 
          // if(data.length > 0) {
          //   if(data[0].product.fst_inner_size && data[0].product.snd_inner_size) {
          //     qty = (data[0].product.fst_inner_size * data[0].product.snd_inner_size * data[0].product.shipper_size)
          //   } else if(data[0].product.fst_inner_size) {
          //     qty = (data[0].product.fst_inner_size * data[0].product.shipper_size);
          //   } else if(data[0].product.snd_inner_size) {
          //     qty = (data[0].product.snd_inner_size * data[0].product.shipper_size);
          //   } else {
          //     qty = data[0].product.shipper_size;
          //   }
          // }

          let qrCode = [];
          let qty = null;
          let data = response.body.data;
          if (data.length > 0) {
            if (data[0].product.fst_inner_size && data[0].product.snd_inner_size) {
              qty = (data[0].product.fst_inner_size * data[0].product.snd_inner_size * data[0].product.shipper_size)
            } else if (data[0].product.fst_inner_size) {
              qty = (data[0].product.fst_inner_size * data[0].product.shipper_size);
            } else if (data[0].product.snd_inner_size) {
              qty = (data[0].product.snd_inner_size * data[0].product.shipper_size);
            } else {
              qty = data[0].product.shipper_size;
            }
            qty = qty + ' X ' + data[0].product.sku_size
          }
          qrCode = response.body.data.map((item) => {
            return {
              "QR Codes": `${(item.product.gtin) ? item.product.gtin : 'UID ' + item.unique_code} B.NO ${item.product_batch.batch_no
                } MFG DT ${moment(item.product_batch.manufacturing_date)
                  .format("DD-MMM-YY")
                  .toUpperCase()} EXP DT ${moment(item.product_batch.expiry_date)
                    .format("DD-MMM-YY")
                    .toUpperCase()} www.ttags.in/${item.unique_code}`,
              "Batch No.": item.product_batch.batch_no,
              "Mfg. Date": moment(
                new Date(item.product_batch.manufacturing_date)
              ).format("DD-MMM-YYYY"),
              "Exp. Date": moment(
                new Date(item.product_batch.expiry_date)
              ).format("DD-MMM-YYYY"),
              "Quantity": qty,
              Subcode: item.unique_code,
              // "MRP": item.product.mrp,
              // "Sub Codes": item.unique_code,
              // "SKU": item.product.sku,
              // "Quantity": qty
            };
          });

          let fileName =
            data[0].product.sku +
            "_" +
            data[0].product_batch.batch_no +
            "_shipper";
          new AngularCsv(qrCode, fileName, this.csvOptions);
          this.loader = false;
        }
      },
      (error) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  downloadPDF(data: any, type: string, filename) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    var fileLink = document.createElement("a");
    fileLink.href = url;
    // it forces the name of the downloaded file
    fileLink.download = filename;

    // triggers the click event
    fileLink.click();

    window.URL.revokeObjectURL(url);
    this.loader = false;
  }

  exportQrcode(id, batch_no) {
    this.loader = true;
    this.apiService
      .get(`company/shipperCodePdf?random_id=${id}`, true, true)
      .subscribe(
        (response: any) => {
          if (response.status === 200) {
            var contentDispositionHeader = response.headers.get(
              "Content-Disposition"
            );
            console.log("response->", response);
            console.log("contentDispositionHeader->", contentDispositionHeader);
            let fileName = contentDispositionHeader.split("=")[1];
            this.downloadPDF(response.body, "application/pdf", fileName);
          }
        },
        (err) => {
          console.error(err);
          this.loader = false;
        }
      );
  }

  //Pdf function
  pdfQrcode(id) {
    this.companyService.getShipperQrcodeList(id).subscribe((res: any) => {
      if (res.success == 1) {
        let qrCode1 = res.data[0];
        let d = {};
        d["Name"] = "Name";
        d["Roll"] = "Roll";
        console.log(qrCode1);
        let qrCode = d["Name"] + " : " + qrCode1["unique_code"];
        let qrCode2 = d["Roll"] + " : " + qrCode1["unique_code"];
        //qrCode['bob'] = '123';
        console.log(this.item);

        //let doc = new jsPDF();
        /* doc.setProperties({title: 'DocumentTitle'});
        doc.text(qrCode,20, 20);
        doc.addPage();
        doc.text(qrCode2,20, 20);
        doc.save('a4.pdf'); */
      } else {
        this._flashMessagesService.show(res.message, {
          cssClass: "alert-success",
        });

        setTimeout(() => {
          this.router.navigate(["/packaging_qrcode"]);
        }, 3000);
      }
    });
  }

  setOrder(value: string) {
    console.log(value, this.order);
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
}
