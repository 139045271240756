import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { BusinessService } from "../service/business.service";
declare var $: any;
import { OrderPipe } from "ngx-order-pipe";
import { FlashMessagesService } from "angular2-flash-messages";
import { Globals } from "./../../../globals";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.css"],
})
export class UserComponent implements OnInit {
  data: any;
  searchText = "";
  sortedCollection: any[];
  order: string = "name";
  reverse: boolean = false;
  result: any = [];
  arr: any = [];
  errors: any = "";
  deletedMsg: string = null;
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  deleteId: any;
  pageLimits = this.globals.pageLimits;
  loader: boolean = false;
  userId: any;
  roleId: any;
  multiData: any = [];
  model: any;
  roleOptions: any;
  resultdetails: any;
  resultUser: any = [];

  constructor(
    private globals: Globals,
    private _flashMessagesService: FlashMessagesService,
    public businessService: BusinessService,
    private route: ActivatedRoute,
    private router: Router,
    private orderPipe: OrderPipe
  ) {
    this.sortedCollection = orderPipe.transform(this.result, this.order);
  
  }

  ngOnInit() {
    this.userData();
    this.roleOptions = {
      multiple: true,
      placeholder: { id: "", text: "Select Users" },
    };
  }

  userData() {
    this.loader = true;
    this.businessService.businessUser_list().subscribe(
      (res: any) => {
        this.loader = false;
        this.result = res.data;
        console.log(this.result);
      },
      (err) => {
        this.loader = false;
      }
    );
  }

  // userDelete(id) {
  //   this.businessService.deleteBusinessUser(id).subscribe(
  //     (res: any) => {
  //       console.log(res);

  //       this.result = res.data;
  //       // this.deletedMsg =true;
  //       if (res["success"] == 1) {       
  //         this.userData();          
  //       } else {
  //         this.errors = res["message"];
  //       }
  //     },
  //     (err) => {}
  //   );
  // }

  setOrder(value: string) {
    console.log(value);
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  // opendeleteModal(id) {
  //   this.deleteId = id;
  //   $("#exampleModal").modal("show");
  // }


  multiChanged(event) {
    this.resultUser.child_user_ids = event.value;
    console.log(this.resultUser.child_user_ids);
  }

  onSubmit(form?: NgForm) {
    let f = form.value;
    f.child_user_ids = this.resultUser.child_user_ids;

    f.user_id = this.userId;
    console.log(f);

    this.businessService.businessRole_parentdatadd(f).subscribe(
      (res: any) => {
        console.log(res);
        this.resultdetails = res;
        if (this.resultdetails.success == 1) {          
          this._flashMessagesService.show("User added Suceessfully", {
            cssClass: "alert-success",
          });
          this.userData();
          setTimeout(() => {
            this.router.navigate(["/user"]);
            this.userData();
          }, 2000);
        } else {
          this.errors = res.message;
          setTimeout(() => {
            this.errors.child_user_ids = false;
          }, 3000);
        }
      },
      (err) => {}
    );
  }
}
