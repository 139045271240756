import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/service/api-service/api-service.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Globals } from "src/app/globals";

@Component({
  selector: "app-customer-care",
  templateUrl: "./customer-care.component.html",
  styleUrls: ["./customer-care.component.css"],
})
export class CustomerCareComponent implements OnInit {
  title: String = "Marketed By Details";
  customerCareDetails: any = [];

  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  pageLimits = this.globals.pageLimits;

  loader = false;
  msg: String;

  searchText = "";
  sortedCollection: any[];
  order: string = "email";
  reverse: boolean = false;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private globals: Globals
  ) {
    this.getCustomerCareDetails();
  }

  ngOnInit() {}

  getCustomerCareDetails() {
    this.loader = true;
    this.apiService.get("company/customer-care/list", true).subscribe(
      (res: any) => {
        if (res.status == 200 && res.body.success == 1 && res.body.data) {
          this.customerCareDetails = res.body.data;
        }

        this.loader = false;
      },
      (err) => {
        this.loader = false;
      }
    );
  }

  setOrder(value: string) {
    console.log(value);
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }
}
