import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/service/api-service/api-service.service";
import { Globals } from "./../../../../globals";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-stock",
  templateUrl: "./stock.component.html",
  styleUrls: ["./stock.component.css"],
})
export class StockComponent implements OnInit {
  stockList = [];
  loader = true;
  searchText = "";
  sortedCollection: any[];
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  display: string = "none";
  pageLimits = this.globals.pageLimits;
  cfaId;
  identity = null;
  backButtonVisible = false;
  order;
  reverse: boolean = false;

  constructor(
    private globals: Globals,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.cfaId = params["id"];
      this.identity = (['cfa', 'factory'].includes(params["i"])) ? params["i"] : null;
      if(this.cfaId) {
        this.backButtonVisible = true;
      }
    });
  }

  ngOnInit() {    
    this.getStockReport();
  }

  getStockReport() {
    let isAdmin = ["1", "2"].includes(localStorage.getItem("role_id")) ? true : false;
    let url = "company/stock-report";
    console.log('isAdmin: ',isAdmin)
    if (isAdmin) {
      url += `?id=${this.cfaId}&identity=${this.identity}`;
    }
    this.apiService.get(url, true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          this.stockList = response.body.data;
        }
        this.loader = false;
      },
      (err) => {
        console.error(err);
        this.loader = false;
      }
    );
  }

  setOrder(value: string) {
    console.log(value);
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

}
